import request from '@/utils/request'

export function getAll(page_size, currentPage, document) {
  return request({
    url: 'person/getAll/' + page_size + '/' + document + '?page=' + currentPage,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: 'person/save',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: 'person/update',
    method: 'put',
    data
  })
}

export function deleteRow(id) {
  return request({
    url: 'person/destroy',
    method: 'delete',
    data: id
  })
}

export function getById(id) {
  return request({
    url: 'person/getById/' + id,
    method: 'get'
  })
}

export function search(data, type) {
  return request({
    url: 'person/search/' + data + '/' + type,
    method: 'get'
  })
}

export function getSeller(branch_id) {
  return request({
    url: 'person/getSeller/' + branch_id,
    method: 'get'
  })
}
