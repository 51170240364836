<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="50">
        <!-- grid -->
        <el-col :span="12" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="row-table">
          <el-row :gutter="20" class="row-filter">
            <el-col :xs="12" :sm="6" :md="4" :lg="4" :xl="4">
              <el-input size="mini" placeholder="Código Interno" v-model="codeInt" clearable @clear="
                codeInt = null;
              getAll();
                                              ">
              </el-input>
            </el-col>
            <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
              <el-select size="mini" v-model="categoryId" filterable clearable placeholder="Categoria" @clear="
                categoryId = null;
              getAll();
                                              ">
                <el-option v-for="item in categories" :key="item.id" :label="item.descripcion" :value="item.id">
                  <span style="float: left"><i class="el-icon-collection-tag"></i>
                    {{ item.descripcion }}</span>
                </el-option>
              </el-select>
            </el-col>
            <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
              <el-input size="mini" placeholder="Descripción" v-model="description" clearable @clear="
                description = null;
              getAll();
                                              ">
              </el-input>
            </el-col>
            <el-col :xs="12" :sm="6" :md="8" :lg="8" :xl="8">
              <el-button type="primary" size="mini" round :loading="loadingBtn" @click="getAll"><i
                  class="el-icon-search"></i>
                Filtrar</el-button>
              <el-button type="success" size="mini" round @click="openModalForm"><i class="el-icon-plus"></i>
                Nuevo</el-button>
              <el-button type="warning" size="mini" round @click="downloadProducts" :loading="loadingDownload"><i
                  class="el-icon-download"></i>
                Descargar</el-button>
              <!-- <el-tooltip class="item" effect="dark" content="Descargar productos" placement="top">
                <el-button type="success" size="mini" round :loading="loadingDownload" @click="downloadProducts"><i
                    class="el-icon-download"></i>
                </el-button>
              </el-tooltip> -->
            </el-col>
          </el-row>
          <el-table :data="tableData" :default-sort="{ prop: 'codigo_interno', order: 'descending' }"
            v-loading="loadingTable" style="width: 100%; margin-top: 20px" height="700" size="samll">
            <div slot="empty">
              <el-empty description="No hay datos"></el-empty>
            </div>
            <el-table-column label="Imagen" width="80">
              <template slot-scope="scope">
                <div class="image-input">
                  <el-image v-if="scope.row.image" class="avatar" alt="image" style="width: 50px; height: 50px"
                    :src="urlImage + scope.row.image" :preview-src-list="[urlImage + scope.row.image]">
                  </el-image>
                  <el-image v-else style="width: 50px; height: 50px" src="/static/images/products/no-image.png"
                    class="avatar" alt="image"></el-image>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="Código"
              prop="codigo"
              sortable
            ></el-table-column> -->
            <el-table-column label="Código Int." prop="codigo_interno" sortable min-width="120"></el-table-column>
            <el-table-column label="Categoria" prop="category.descripcion" sortable min-width="120">
              <template slot-scope="scope">
                <div>
                  <i class="el-icon-collection-tag"></i>
                  {{ scope.row.category.descripcion }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Descripción" prop="descripcion" sortable min-width="300"></el-table-column>
            <el-table-column label="Costo" prop="costo" sortable min-width="120">
              <template slot-scope="scope">
                <div>$ {{ formatNumber(scope.row.costo) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="P. Venta" prop="precio_venta" sortable min-width="120">
              <template slot-scope="scope">
                <div>$ {{ formatNumber(scope.row.precio_venta) }}</div>
              </template>
            </el-table-column>
            <el-table-column align="right" width="90">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="Editar" placement="top">
                  <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit"
                    circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
                  <el-button :disabled="scope.row.usado == '1'" size="mini" type="danger"
                    @click="handleDelete(scope.$index, scope.row)" icon="el-icon-delete" circle></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <div v-if="tableData.length > 0" class="pagination-box animated fadeInRight"
            style="text-align: end; padding-top: 10px">
            <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
              @current-change="handleCurrentPage"></el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <modal-form :openModal="openModal" :dataForm="dataForm" :edit="edit"
      @closeModal="closeModal($event, true)"></modal-form>
  </div>
</template>

<script>
import ModalForm from "./ModalForm.vue";
import {
  getAll,
  deleteData,
  validateCodeExist,
  downloadProducts
} from "@/api/product";
import { getAll as getCategories } from "@/api/product/category.js";
import { formatInt, formatNumber } from "@/utils/number";
import { actualDate } from "@/utils/date";
export default {
  name: "ListProduct",
  components: {
    ModalForm
  },
  data() {
    return {
      urlApi: process.env.VUE_APP_ROOT_API,
      url: process.env.VUE_APP_ROOT,
      baseURLImage: process.env.VUE_APP_ROOT_API + "/product/uploadImage",
      loadingTable: false,
      loadingBtn: false,
      loadingDownload: false,
      edit: false,
      search: "",
      tableData: [],
      categories: [],
      unitMeasurements: [],
      taxs: [],
      salePriceFinal: 0,
      categories: [],
      //
      pageSize: 20,
      currentPage: 1,
      total_rows: 0,
      // filter
      codeInt: null,
      categoryId: null,
      description: null,
      // image
      urlImage: process.env.VUE_APP_ROOT + "static/images/uploads/",
      // modal form
      openModal: false,
      dataForm: {}
    };
  },
  methods: {
    downloadProducts() {
      let me = this;
      me.loadingDownload = true;
      downloadProducts({
        categoryId: me.categoryId
      })
        .then(({ data }) => {
          var dateString = actualDate().substring(0, 10);
          const url = URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Productos " + dateString);
          document.body.appendChild(link);
          link.click();
          me.loadingDownload = false;
        })
        .catch(function (error) {
          me.loadingDownload = false;
          console.log(error);
          me.$message({
            type: "error",
            message: "Error! ",
          });
        });
    },
    closeModal(data) {
      this.openModal = false
      this.edit = false
      this.dataForm = {}
      if (data) {
        if (data.refreshTable) {
          this.getAll()
        }
      }
    },
    openModalForm() {
      this.openModal = true
    },
    async validateIntCode(code) {
      let exist = false;
      await validateCodeExist(code)
        .then(({ data }) => {
          console.log("datos", data);
          exist = data ? true : false;
        })
        .catch(function (error) {
          console.log(error);
        });
      return exist;
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getAll();
    },
    getAll() {
      this.loadingTable = true;
      this.loadingBtn = true;
      if (
        this.codeInt != null ||
        this.categoryId != null ||
        this.description != null
      ) {
        this.currentPage = 1;
      }
      getAll(
        this.pageSize,
        this.currentPage,
        this.codeInt,
        this.categoryId,
        this.description
      )
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loadingTable = false;
          this.loadingBtn = false;
        })
        .catch(function (error) {
          this.loadingBtn = false;
          console.log(error);
        });
    },
    handleEdit(index, row) {
      this.dataForm = row
      this.edit = true
      this.openModal = true
    },
    handleDelete(index, row) {
      var me = this;
      this.loadingTable = true;
      this.$swal
        .fire({
          title: "<strong>Atención!</strong>",
          icon: "warning",
          html: "Deseas borrar este producto?",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          focusConfirm: false,
          confirmButtonText:
            '<i class="mdi mdi-clipboard-check-outline"></i> Si',
          cancelButtonText:
            '<i class="mdi mdi-close-circle-outline"></i> Cancelar',
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteData(row.id)
              .then(({ data }) => {
                if (data.code === 200) {
                  this.getAll();
                  setTimeout(() => {
                    me.loadingTable = false;
                  }, 500);
                } else {
                  this.$message.error("Error! " + data.error.errorInfo[2]);
                }
              })
              .catch(function (error) {
                this.loadingTable = false;
                console.log("Err: ", error);
              });
          } else {
            me.loadingTable = false;
          }
        });
    },
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(formatInt(number));
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },

    getCategories() {
      getCategories()
        .then(({ data }) => {
          this.categories = data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created() {
    this.getAll();
    this.getCategories();
  },
};
</script>

<style lang="scss" scope>
.talbe-pivot {
  margin-bottom: 20px;

  .add-row {
    text-align: left;
    margin-top: 10px;

    .el-link--default {
      color: #5f8fdf;
    }

  }

  .el-table {
    .cell {
      padding-left: 5px;
      padding-right: 5px;

      .btn-actions {
        text-align: right;
      }
    }
  }
}

.el-alert {
  padding: 2px 16px !important;
}

.avatar {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border: 1px solid #fff;
  // margin-left: 2px;
  // margin-top: 5px;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(49, 49, 93, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.5s;

  &:hover {
    box-shadow: 0px 3px 10px 0 rgba(49, 49, 93, 0.08),
      0px 2px 7px 0 rgba(0, 0, 0, 0.08);
  }
}

@media (max-width: 990px) {
  .row-table {
    margin-top: 50px;
  }
}

@media (max-width: 766px) {
  .row-filter {
    .el-col {
      margin-top: 10px;
    }
  }
}
</style>