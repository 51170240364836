const Pause = 'pause'

export function setPause(pause){
  var p = this.getPause();
  console.log(p);
  var p = JSON.stringify(pause);
  return sessionStorage.setItem(Pause, p);
}

export function getPause(){
  return sessionStorage.getItem(Pause);
}

export function removePause() {
  return sessionStorage.removeItem(Pause)
}