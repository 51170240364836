<template>
  <el-dialog
    :visible.sync="modalPrintOpen"
    :before-close="modalPrintClose"
    :width="'420px'"
    :close-on-click-modal="false"
    :top="'5vh'"
    :destroy-on-close="true"
  >
    <div slot="title">Documento # {{ data.consecutive }}</div>
    <vue-scroll class="scroller-print">
      <el-card class="box-card" style="font-size: 12px">
        <div class="center bold">{{ data.branch }}</div>
        <div class="center bold">NIT: {{ data.nit }}</div>
        <div class="center bold">{{ data.branch_address }}</div>

        <el-divider class="divider"></el-divider>

        <div class="center bold">FACTURA DE VENTA #{{ data.consecutive }}</div>
        <div class="center bold">REGIMEN COMÚN</div>
        <div class="center">2020-07-16 11:45:00 am</div>

        <el-divider class="divider"></el-divider>

        <div>Cliente: {{ data.client }}</div>
        <div>Cedula O NIT: {{ data.client_nit }}</div>
        <div>Dirección: {{ data.client_address }}</div>
        <div>Vendedor: {{ data.seller }}</div>

        <el-divider class="divider"></el-divider>

        <el-table :data="detailData" style="width: 100%" size="mini">
          <el-table-column prop="description" label="Descripción" width="130"></el-table-column>
          <el-table-column prop="quantity" label="Cantidad" align="center"></el-table-column>
          <el-table-column prop="price" label="Precio" align="right" width="100"></el-table-column>
        </el-table>
        <div>
          <label class="text-totals">Subtotal</label>
          <label class="value-totals">$ {{ this.formatNumber(this.subtotal) }}</label>
        </div>
        <div>
          <label class="text-totals">Descuento</label>
          <label class="value-totals">- $ {{ this.formatNumber(this.discount) }}</label>
        </div>
        <div>
          <label class="text-totals">IVA 19%</label>
          <label class="value-totals">$ {{ this.formatNumber(this.iva, true) }}</label>
        </div>
        <div>
          <label class="text-totals bold">Total</label>
          <label class="value-totals bold">$ {{ this.formatNumber(this.total, true) }}</label>
        </div>

        <el-divider class="divider"></el-divider>

        <div class="center bold">FORMAS DE PAGO</div>

        <el-table :data="payments" style="width: 100%" :show-header="false" size="mini">
          <el-table-column prop="payment.descripcion" label="Descripción"></el-table-column>
          <el-table-column prop="valor" label="Precio" align="right" width="100">
            <template slot-scope="props">$ {{props.row.valor}}</template>
          </el-table-column>
        </el-table>

        <div>
          <label class="text-totals return-client bold">CAMBIO</label>
          <label class="value-totals">$ {{ this.formatNumber(total - payment, true) }}</label>
        </div>

        <div class="center footer-text">GRACIAS POR SU COMPRA</div>
      </el-card>
    </vue-scroll>
    <div slot="footer" class="dialog-footer">
      <el-dropdown split-button type="primary" @click="handleClick">
        <i class="el-icon-printer"></i> Imprimir
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-message">Factura Electronica</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-dialog>
</template>
<script>
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
} from "@/utils/number";
export default {
  name: "Print",
  props: ["modalPrintOpen", "document", "detail"],
  watch: {
    document: {
      deep: true,
      handler(val, oldVal) {
        if (Object.keys(val).length !== 0) {
          this.assignData(val);
          this.payments = val.payment_detail;
          this.payment = 0;
          if (val.payment_detail.length > 0) {
            val.payment_detail.forEach((el) => (this.payment += el.valor));
          }
        }
      },
    },
    detail: {
      deep: true,
      handler(val, oldVal) {
        this.detailData = [];
        if (val.length > 0) {
          this.assignDataDetail(val);
        }
      },
    },
  },
  data() {
    return {
      data: {
        id: "",
        consecutive: "",
        branch: "",
        branch_nit: "",
        branch_address: "",
        client: "",
        client_name: "",
        client_address: "",
        client_nit: "",
        seller: "",
      },
      detailData: [
        {
          description: "",
          quantity: 0,
          price: 0,
        },
      ],
      payments: [],
      payment: 0,
      subtotal: 0,
      total: 0,
      discount: 0,
      iva: 0,
    };
  },
  methods: {
    modalPrintClose() {
      this.$emit("close");
    },
    assignData(data) {
      this.data = {
        id: data.id,
        consecutive: data.consecutivo,
        branch: data.branch.razon_social,
        branch_nit: data.branch.nit,
        branch_address: data.branch.direccion,
        client: data.person.nombre,
        client_nit: data.person.documento,
        client_address: data.person.direccion,
        seller: data.seller.nombre,
        discount: data.descuento_valor,
      };
    },
    assignDataDetail(data) {
      let me = this;
      me.subtotal = 0;
      me.total = 0;
      me.discount = 0;
      me.iva = 0;
      data.forEach(function (el, ind) {
        me.subtotal += formatInt(el.precio) * formatInt(el.cantidad);
        me.discount += el.descuento;
        me.iva = el.iva;
        me.detailData.push({
          description: el.product.descripcion,
          quantity: el.cantidad,
          price: "$ " + formatNumber(formatInt(el.precio)),
        });
      });
      this.discount = this.discount + this.document.descuento_valor
      this.iva = ((this.subtotal - this.discount) * me.iva) / 100;
      this.total = roundToTheNearestAnything(
        this.subtotal - this.discount + this.iva
      );
    },
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      number = number == "" ? 0 : number;
      var num = formatNumber(number);
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
    handleClick() {
      console.log("print");
    },
  },
};
</script>
<style lang="css" scope>
.el-dialog__body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.center {
  text-align: center;
}
.divider {
  margin: 10px 0;
}
.table-details {
  font-size: 12px;
}
.text-totals {
  /* padding-left: 100px; */
}
.value-totals {
  float: right;
  padding-right: 10px;
}
.bold {
  font-weight: bold;
}
.footer-text {
  margin-top: 20px;
}
.scroller-print {
  padding: 10px;
  padding-top: 0px;
  width: 100%;
  height: 600px;
  box-sizing: border-box;
}
.return-client {
  padding-left: 10px;
}
</style>