const Demo = 'demo'
const Token = 'token'
const User = 'user'
const Base = 'base'
const DataPos = 'data_pos'
const Config = 'config'
const DataPrincipalBranch = 'principal_branch'

export function getToken() {
  return sessionStorage.getItem(Token)
}

export function setToken(token) {
  return sessionStorage.setItem(Token, token)
}

export function getUser(param) {
  var data = sessionStorage.getItem(User)
  data = JSON.parse(data)
  if (param && data) {
    return data[param]
  }
  return data
}

export function setUser(user) {
  sessionStorage.setItem(User, user)
}

export function setBaseCash(base) {
  sessionStorage.setItem(Base, base)
}
export function getBaseCash() {
  var data =  sessionStorage.getItem(Base)
  data = JSON.parse(data)
  return data
}

export function setDataPos(data) {
  sessionStorage.setItem(DataPos, data)
}
export function getDataPos() {
  var data =  sessionStorage.getItem(DataPos)
  data = JSON.parse(data)
  return data
}

export function setPrincipalBranch(data) {
  sessionStorage.setItem(DataPrincipalBranch, data)
}
export function getPrincipalBranch() {
  var data =  sessionStorage.getItem(DataPrincipalBranch)
  data = JSON.parse(data)
  return data
}

// REMOVE AUTH
export function removeToken() {
  return sessionStorage.removeItem(Token)
}

export function removeUser() {
  return sessionStorage.removeItem(User)
}

export function removeBaseCash() {
  return sessionStorage.removeItem(Base)
}

export function removeDataPos() {
  return sessionStorage.removeItem(DataPos)
}

export function setConfig(config){
  return sessionStorage.setItem(Config, config);
}

export function getConfig(){
  return sessionStorage.getItem(Config);
}

export function removeConfig() {
  return sessionStorage.removeItem(Config)
}

// DEMO
export function getDemo() {
  return sessionStorage.getItem(Demo)
}

export function setDemo() {
  sessionStorage.setItem(Demo, 'Demo')
}

export function removeDemo() {
  sessionStorage.removeItem(Demo)
}


