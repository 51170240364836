const axios = require('axios');
import * as qz from "qz-tray";
import { getUser, getDemo } from "@/utils/auth";
import { actualDate } from "@/utils/date";
import { getDocumentTransfer } from "@/api/document";
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
} from "@/utils/number";

var user = getUser();
var demo = getDemo();
var printerDefault = '';
var dataPrint = {};
var print_subtotal = 0;
var print_total = 0;
var print_discount = 0;
var print_iva = 0;
var print_ipc = 0;
var print_quantity = 0;
var detailTableText = "";
var detailData = [];

export function getDocument(id) {
  resetValues();
  return getDocumentTransfer(id)
    .then(({ data }) => {
      var print_payment = 0;
      var paymentTableText = "";
      if (data.document.payment_detail.length > 0) {
        data.document.payment_detail.forEach(function (el) {
          paymentTableText +=
            '<tr><td style="padding-left:10px;">' +
            el.payment.descripcion +
            '</td><td style="text-align:right;padding-right:10px">' +
            "$ " +
            formatNumber(formatInt(el.valor)) +
            "</td></tr>";
          print_payment += formatInt(el.valor);
        });
      }

      // data detail
      if (data.detail.length > 0) {
        data.detail.forEach(function (el, ind) {
          if (el.transaccion == 1) {
            detailTableText +=
              '<tr><td style="padding-left:10px;">' +
              el.product.descripcion +
              "<br>" +
              el.product.codigo +
              '</td><td style="">' +
              el.product.category.descripcion +
              '</td><td style="text-align:center;">' +
              el.cantidad +
              '</td><td style="text-align:right;padding-right:10px">' +
              "$ " +
              formatNumber(formatInt(el.precio)) +
              "</td></tr>";
            print_subtotal += formatInt(el.precio) * formatInt(el.cantidad);
            print_discount += formatInt(el.descuento);
            print_iva = el.iva;
            print_ipc += el.ipc * el.cantidad;
            detailData.push({
              description: el.product.descripcion,
              quantity: el.cantidad,
              price: "$ " + formatNumber(formatInt(el.precio)),
            });
            print_quantity += parseFloat(el.cantidad);

          }
        });
        print_discount =
          print_discount + formatInt(data.document.descuento_valor);
        print_iva =
          ((print_subtotal - print_discount) * print_iva) / 100;
        print_total = roundToTheNearestAnything(
          print_subtotal -
          print_discount +
          print_iva +
          print_ipc
        );
      }
      //
      dataPrint = {
        company: data.company,
        document: data.document,
        detail: data.detail,
        payments: data.document.payment_detail,
        // document
        id: data.document.id,
        consecutive: data.document.consecutivo,
        type: data.document.type,
        branch: data.document.branch.razon_social,
        branch_nit: data.document.branch.nit,
        branch_address: data.document.branch.direccion,
        client: (data.document.type.id === 15) ? 'Bodega Principal' : data.document.person.nombre,
        // client_nit: data.document.person.documento,
        // client_address: data.document.person.direccion,
        // client_phone: data.document.person.telefono,
        // seller: data.document.seller.nombre,
        discount: data.document.descuento_valor,
        // detail
        detailDataSet: {
          print_subtotal: print_subtotal,
          print_total: print_total,
          print_discount: print_discount,
          print_iva: print_iva,
          print_ipc: print_ipc,
          print_quantity: print_quantity,
          detailTableText: detailTableText,
          detailData: detailData,
        },
        // paiment
        paymentTableText: paymentTableText,
        print_payment: print_payment,
      }
      startPrint();
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function startPrint() {
  if (!qz.websocket.isActive()) {
    console.log('websocket is not active');
    startConnectionPrint();
  } else {
    getPrinterDefault();
  }
  setTimeout(() => {
    console.log('enviando a imprimir a: ', printerDefault);
    sendPrinter(printerDefault);
  }, 1000);
}

export function startConnectionPrint() {
  setTimeout(() => {
    // Authentication setup ///
    var privateKey =
      "-----BEGIN PRIVATE KEY-----\n" +
      "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCn13RTAjw4IfTw\n" +
      "d46mk3Lw7YLFJbIGdZvV4ieg51+fqqyUk81+q0icBIAnsrg3j2J+oBlwd1HLgaeI\n" +
      "C+8tEVn3ed9tzvxhJ5iGmd9DUorPIP2RsX+62uKi7OV4fXYBkqSXKWdaoI0nLEwf\n" +
      "FkCL7b8UN3LUazECIsK4bXeYIFMgAERYr6lvtP8HTJ3t6d/W0BWNQgXpUMuGJhvo\n" +
      "5yiJhsVZp8YjF/xyBcQRZjx58Ze4n426dM4lT5cH7EDJk5iogL/9D5YmPZ8dFMVZ\n" +
      "yaAfVVvT1x3vC7GqboKbYISNZLyVM3/+F3ikkcGe/NLbKUwdnFc+rKOdGp/Dup8T\n" +
      "HWVpm9MfAgMBAAECggEAGV2Z8mudjzwuiOMAt5MEJX43q7TZNVmz3fyqUYp2LKq+\n" +
      "H0xBG7omJJIXjihX5+mvEIUAozsdLPzgxVJMaMq9BoYwKqfEA6jYx8rcb4+7c7dA\n" +
      "KzMgoywuYUIdIfsQCQfRARlcSdmGy5tJu88AnUUmghcpLwouI2z+caH1l5OXDHhP\n" +
      "LbN+opDi0EhCd+PoNInkpj9s12/tl8rOOlHJSGUSgIAZGyafS5llBm3JoO4dZUmQ\n" +
      "0Lq1tKI25ai7B7EtZ+3Dyvya6N+5ACXucwlsufROc4kLrGbAffEPkreHydD78iSf\n" +
      "d1VMUuDABnUawPOLnd3yKBwAfvxN6jhjlYThw7rNRQKBgQDVX17U7H+IZsbvbknT\n" +
      "XsJQJlE+fdUYI6EImBDs9MG34akpTi+MdgdKJOKTJIVQIm5pxOD1nxzrY1vlNRML\n" +
      "hA8o0ZACcsION/476puRfG2LhxVak+gN+P0NlWXY4R1QsLSsc/KaL1MoBO2lom7+\n" +
      "C9l+KIG544ZzfK/hirVhPKQQZQKBgQDJX3iMS37VLu+2OQ26L9xstH33C9R73DJJ\n" +
      "mpPXra1KA1hgPYETy7769LMdYV7vKsyh4++4VgWAmSLkOK0H/ViQpmcW+kyyLQ9w\n" +
      "euD87zRcjIIY0ehI8JyLHX9Dxcuy4/oJDn+0IRXXkHDfSrw6kfkFuP+khTSRbbAj\n" +
      "q0L7vyfjMwKBgCwC5f6pYYzzFimg6B1vOElr/eoT7nZyMu8g3qzht2uEYm4gsHIa\n" +
      "jlpezVVofQ8QaxHqCDThwwdSkUvv7KO9c5sJztiSbzCboBcfx0L1FWtoS6FJTN5E\n" +
      "831vyXg4xuEz8bB4ohvnkxF4IsS9goNoUk5RcKC1K7B6kZTGFa+e4IB5AoGAccGK\n" +
      "+cYUKh3PsKLeTFWJMadsmUiHikjD56x45Bfqkqr9G7Di3+nzQxVEsc41sOn+m12G\n" +
      "KB4AEbtwaHhT1vWZOG2oQuS2utiVpjbkLj561+VQAPFjRQEXjcbmA8US40XCE6er\n" +
      "IHioPBQOjzVKy9N2V3NAhxHwtk6+eAfhWRGNWN0CgYA6XAiF1Al+kYkosSKsjpxo\n" +
      "BdKzT+5vCw37Iq3F+DYxzRb8MKx9XpeGoEWbG62Xez7eZ2B6UaARGDu43Ioa/6vk\n" +
      "bh9647T6nehoGlagzdu92kO5FCFsoIB2pBBZAS5k7S8jtSS7To2W3FDY/o3wwIRg\n" +
      "spuV+871lajMrXi3/faa/w==\n" +
      "-----END PRIVATE KEY-----";

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        try {
          var pk = KEYUTIL.getKey(privateKey);
          var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
          sig.init(pk);
          sig.updateString(toSign);
          var hex = sig.sign();
          // console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
          resolve(stob64(hextorstr(hex)));
        } catch (err) {
          console.error(err);
          reject(err);
        }
      };
    });
    qz.security.setCertificatePromise(function (resolve, reject) {
      resolve(axios.get(process.env.VUE_APP_ROOT_API + '/certs/getQzCertificate').then(response => response.data))
    });
    if (!qz.websocket.isActive()) {
      qz.websocket.connect().then(function () {
        console.log("Connected QZ-tray! ", qz.websocket.isActive());
        qz.printers
          .getDefault()
          .then(function (data) {
            printerDefault = data;
          })
          .catch(function (error) {
            console.log("error: ", error);
          });
      });
    }
  }, 300);
}

function getPrinterDefault() {
  qz.printers
    .getDefault()
    .then(function (data) {
      printerDefault = data;
    })
    .catch(function (error) {
      console.log("error: ", error);
    });
}

function sendPrinter(printer) {
  var recived = '';
  var client = '';
  var totals = '';
  var config = qz.configs.create(printer, { scaleContent: false }); // Create a default config for the found printer
  var document_text = dataPrint.type.descripcion;

  client = '<div style="padding-left:10px;">BODEGA DESTINO: ' +
    user.branch.razon_social +
    "</div>" +
    '<div style="padding-left:10px;">DESPACHADO POR: ' +
    dataPrint.client;
  totals = '<tr><td style="padding-left:10px;border-top: 1px dashed;">TOTAL</td>' +
    '<td style="border-top: 1px dashed;"></td>' +
    '<td style="text-align:center;border-top: 1px dashed;">' +
    print_quantity +
    '</td><td style="text-align:right;padding-right:10px;border-top: 1px dashed;"></td></tr></table>';
  recived = '<div style="padding-left:10px;">RECIBIDO POR:____________________________________________________</div>';

  var documentHead =
    '<div style="text-align:center;">' +
    dataPrint.company.razon_social +
    "</div>" +
    '<div style="text-align:center;">NIT: ' +
    dataPrint.company.nit +
    "</div>" +
    '<div style="margin: 10px 0 10px 0;"></div>' +
    '<div style="text-align:center;">' +
    dataPrint.branch +
    "</div>" +
    '<div style="text-align:center;">' +
    dataPrint.branch_address +
    "</div>" +
    '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
    // invoice
    '<div style="text-align:center;">' +
    document_text +
    " #" +
    dataPrint.type.prefijo +
    "" +
    dataPrint.consecutive.toString().padStart(6, "0") +
    "</div>" +
    '<div style="text-align:center;">RESPONSABLES DE IVA</div>' +
    '<div style="text-align:center;">' +
    actualDate(dataPrint.document.created_at) +
    "</div>";

  var documentFooter = recived +
    '<div style="padding-left:10px;">OBSERVACIÓN: ' +
    dataPrint.document.observacion +
    "</div>" +
    '<div style="font-size:10px;text-align:center;margin-top:20px;margin-bottom:10px;">' +
    'Contactanos Oficina principal 032 695 4503 Celular 316 7433278' +
    "</div>" +
    '<div style="font-size:12px;text-align:center;margin-top:20px;margin-bottom:10px;">' +
    'Siguenos en nuestras redes sociales: xplod.nyc' +
    "</div>" +
    '<div style="font-size:10px;text-align:center;margin-bottom:10px;">https://www.xplodnyc.com</div>' +
    '<div style="text-align:center;margin-top:2px;margin-bottom:2px;font-size:9px">Desarrollado por aplicacionesexcel.com</div>';

  var background = "";
  if (demo) {
    background =
      "background-image: url('" +
      process.env.VUE_APP_ROOT +
      "static/images/demo.png');";
    documentHead =
      '<div style="font-size:30px;text-align:center;">DEMO</div>';
    documentFooter =
      '<div style="font-size:30px;text-align:center;">DEMO</div>';
  }
  var printData = [
    //   '\nN\n',
    //  'q609\n',
    {
      type: "pixel",
      format: "html",
      flavor: "plain",
      data:
        "<html>" +
        '<body style="font-size:11px;' +
        background +
        '">' +
        documentHead +
        // table detail
        '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
        // client
        client +
        "</div>" +
        // table detail
        '<table style="width:100%;font-size:11px;">' +
        '<tr><td style="border-top: 1px dashed;border-bottom: 1px dashed;">Descripción</td>' +
        '<td style="text-align:center;border-top: 1px dashed;border-bottom: 1px dashed;">Categoria</td>' +
        '<td style="text-align:center;border-top: 1px dashed;border-bottom: 1px dashed;">Cant.</td>' +
        '<td style="text-align:right;border-top: 1px dashed;border-bottom: 1px dashed;padding-right:10px">Precio</td></tr>' +
        detailTableText +
        totals +
        "</table>" +
        '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +

        documentFooter +
        '<div style="margin: 20px 0 20px 0;border-style: dashed; border-width: 1px;"></div>' +
        "</div>" +
        "</body>" +
        "</html>",
      options: { language: "EPL" }
    },
    // '\nP1,1\n'
  ];
  // console.log(config, printData);
  qz.print(config, printData)
    .then(function () {
      console.log('Print successfull');
    })
    .catch(function (error) {
      console.log("error", error);
    });
}

function resetValues() {
  dataPrint = {};
  print_subtotal = 0;
  print_total = 0;
  print_discount = 0;
  print_iva = 0;
  print_ipc = 0;
  print_quantity = 0;
  detailTableText = "";
  detailData = [];
}