import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import createPersistedState from 'vuex-persistedstate'
// modules
import document from './modules/document'
import refund from './modules/refund'
import drawer from './modules/drawer'
import expenses from './modules/expenses'

import { getUser, removeToken, removeUser, removeBaseCash, removeDataPos, removeDemo, removeConfig } from '@/utils/auth'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	state: {
		layout: {
			navPos: 'left', //top, bottom, left, right, false
			toolbar: 'top', //top, bottom, false
			footer: true, //above, below, false
			boxed: false, //true, false
			roundedCorners: false, //true, false
			viewAnimation: 'fade-top' // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
		},
		splashScreen: true,
		logged: getUser()
	},
	mutations: {
		setLayout(state, payload) {
			if (payload && payload.navPos !== undefined)
				state.layout.navPos = payload.navPos

			if (payload && payload.toolbar !== undefined)
				state.layout.toolbar = payload.toolbar

			if (payload && payload.footer !== undefined)
				state.layout.footer = payload.footer

			if (payload && payload.boxed !== undefined)
				state.layout.boxed = payload.boxed

			if (payload && payload.roundedCorners !== undefined)
				state.layout.roundedCorners = payload.roundedCorners

			if (payload && payload.viewAnimation !== undefined)
				state.layout.viewAnimation = payload.viewAnimation
		},
		setLogin(state, payload) {
			state.logged = true
		},
		setLogout(state, payload) {
			state.layout.navPos = null
			state.layout.toolbar = null
			state.logged = false
			removeToken()
			removeUser()
			removeBaseCash()
			removeDataPos()
			removeDemo()
			removeConfig()
		},
		setSplashScreen(state, payload) {
			state.splashScreen = payload
		}
	},
	actions: {

	},
	modules: {
		document,
		refund,
		drawer,
		expenses
  },
	getters,
	plugins: [createPersistedState({ paths: ['layout'] })],
	strict: false
})
