import request from '@/utils/request'

export function store(data) {
  return request({
    url: 'user/store',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: 'user/update',
    method: 'put',
    data
  })
}

export function getById(id) {
  return request({
    url: 'user/getById/' + id,
    method: 'get'
  });
}

export function deleteUser(id) {
  return request({
    url: 'user/destroy/' + id,
    method: 'delete'
  });
}

export function getUsers() {
  return request({
    url: 'user/users',
    method: 'get'
  })
}

export function getOldUsers(branch_id) {
  return request({
    url: 'user/oldUsers/' + branch_id,
    method: 'get'
  })
}

export function credentials() {
  return request({
    url: 'user/credentials',
    method: 'get'
  })
}