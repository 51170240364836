<template>
  <el-dialog title="Lista de vendedores" :visible.sync="moadlOpen" :before-close="modalClose">
    <el-table
      :data="gridData.filter(data => !search || data.seller.nombre.toLowerCase().includes(search.toLowerCase()))"
      :loading="loading"
      height="450"
    >
      <el-table-column property="seller.documento" label="Documento" width="150"></el-table-column>
      <el-table-column property="seller.nombre" label="Nombre" width="300"></el-table-column>
      <el-table-column property="seller.ciudad" label="Ciudad" width="100"></el-table-column>
      <el-table-column align="right">
        <template slot="header" slot-scope="scope">
          <el-input ref="searchSeller" v-model="search" size="mini" placeholder="Buscar" />
        </template>
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="success"
            @click="handleSelected(scope.$index, scope.row)"
          >Seleccionar</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { getSeller } from "@/api/person";
import { getUser } from "@/utils/auth";
export default {
  name: "Seller",
  props: ["moadlOpen"],
  watch: {
    moadlOpen(newVal, oldVal) {
      if (newVal) {
        this.getSeller();
        this.$nextTick(() => this.$refs.searchSeller.focus());
      }
    }
  },
  data() {
    return {
      user: getUser(),
      gridData: [],
      dialogTableVisible: false,
      loading: false,
      search: ""
    };
  },
  methods: {
    modalClose() {
      this.gridData = [];
      this.$emit("close");
    },
    getSeller() {
      this.loading = true;
      getSeller(this.user.branch.id)
        .then(({ data }) => {
          this.gridData = data;
          this.loading = false;
        })
        .catch(function(error) {
          this.loading = false;
          console.log(error);
        });
    },
    handleSelected(indx, row) {
      this.$emit("seller", row);
    }
  }
};
</script>