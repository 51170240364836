<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <modal-list-document :typeDocument="form.type_id" :transaction="1"></modal-list-document>

          <el-form :model="form" :rules="rules" ref="form" status-icon label-width="70px" size="medium" class="form"
            :inline="true" :label-position="'top'">
            <el-form-item label="Cantidad" prop="quantity">
              <el-input-number v-model="form.quantity" :min="1" :max="100000"></el-input-number>
            </el-form-item>
            <el-form-item label="Código / Producto" prop="code">
              <el-select v-model="form.code" filterable remote clearable reserve-keyword placeholder="Buscar Producto..."
                :remote-method="remoteMethod" :loading="loadingProduct" @clear="form.code = ''"
                loading-text="Cargando...">
                <i slot="prefix" class="el-input__icon el-icon-shopping-cart-2"></i>
                <el-option v-for="item in options" :key="item.id" :label="item.descripcion" :value="item.codigo_interno"
                  class="options-product">
                  <div><strong><i class="mdi mdi-cart-outline"></i> {{ item.descripcion }}</strong></div>
                  <span style="color: #8492a6; font-size: 13px">
                    <i class="mdi mdi-barcode-scan"></i> {{ item.codigo_interno }}
                    <i class="mdi mdi-tag-outline"></i> {{ item.category.descripcion }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Empleado" prop="employed_id">
              <el-select v-model="form.employed_id" filterable placeholder="Selecciona una empleado"
                @clear="form.employed_id = ''" clearable>
                <el-option v-for="item in employeds" :key="item.id" :label="item.complete_name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <label slot="label">&nbsp;</label>
              <el-button type="primary" @click="submitForm('form')" :loading="loading" icon="el-icon-plus">Agregar
              </el-button>
              <el-button type="success" icon="el-icon-check" :disabled="btnSaveDisabled" @click="saveLoad">Guardar
                Ajuste de Entrada</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <list :get="getData" @complete="complete($event, true)" @quantity_detail="validateQuantityDetail($event, true)"
            @delete="deleteProduct($event, true)"></list>
        </el-col>
      </el-row>
    </div>
    <modal-without-products :openModalWithoutProducts="openModalWithoutProducts"
      :dataWithoutProducts="dataWithoutProducts" @close="closeModalWithoutProducts"></modal-without-products>
  </div>
</template>
<script>
import ModalListDocument from '../ModalListDocument.vue';
import ModalWithoutProducts from '../ModalWithoutProducts.vue';
import { loadInventory, saveLoadInventory } from "@/api/document";
import { getAll as getEmployed } from "@/api/administration/employed";
import { searchProductByData } from "@/api/product";
import List from "./List.vue";
export default {
  name: "InputSetting",
  components: {
    List,
    ModalWithoutProducts,
    ModalListDocument,
  },
  data() {
    return {
      loading: false,
      loadingTable: false,
      btnSaveDisabled: true,
      openModalWithoutProducts: false,
      loadingProduct: true,
      options: [],
      dataWithoutProducts: [],
      getData: false,
      modalOpen: false,
      employeds: [],
      form: {
        code: "",
        quantity: 1,
        type_id: 7, // ajuste de entrada
        employed_id: null,
        simpleProductInventory: [] // inventario de productos simples
      },
      rules: {
        code: [
          {
            required: true,
            message: "Ingresa un código o descripción",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    remoteMethod(query) {
      if (query !== "" && query.length > 2) {
        this.loadingClient = true;
        searchProductByData({ data: query })
          .then(({ data }) => {
            this.loadingProduct = false;
            this.options = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.options = [];
      }
    },
    closeModalWithoutProducts() {
      this.openModalWithoutProducts = false
      this.dataWithoutProducts = []
    },
    validateQuantityDetail(data) {
      this.btnSaveDisabled = data
    },
    saveLoad() {
      let me = this;
      this.$msgbox({
        title: "Atención",
        message:
          "Se ajustarán los datos en el inventario de la tienda. Desea continuar?",
        showCancelButton: true,
        confirmButtonText: "Si, ajustar entrada",
        cancelButtonText: "No, cancelar",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Cargando datos...";
            saveLoadInventory({ type_id: this.form.type_id })
              .then(({ data }) => {
                if (data.code === 200) {
                  me.getData = true;
                  me.resetForm("form");
                  me.$message({
                    message: "Se ha ajustado el inventario en la tienda",
                    type: "success",
                  });
                } else {
                  me.$message.error("Error: " + data.error);
                }
                me.loadingTable = false;
                done();
                instance.confirmButtonLoading = false;
              })
              .catch(function (error) {
                instance.confirmButtonLoading = false;
                me.loadingTable = false;
                me.$message.error("Error! " + error.response.data.message);
                console.log("Err: ", error);
              });
          } else {
            done();
          }
        },
      });
    },
    submitForm() {
      let me = this;
      me.$refs['form'].validate((valid) => {
        if (valid) {
          me.loading = true;
          loadInventory(me.form)
            .then(({ data }) => {
              if (data.code === 200) {
                me.addSimpleProductsInventory(data)
                me.getData = true;
                me.resetForm("form");
                me.$message({
                  message: "Producto cargado!",
                  type: "success",
                });
              } else {
                me.$message.error("Error: " + data.data);
                if (data.withoutBalance.length > 0) {
                  me.openModalWithoutProducts = true
                  me.dataWithoutProducts = data
                }
              }
              me.loading = false;
              me.loadingTable = false;
            })
            .catch(function (error) {
              me.loading = false;
              me.loadingTable = false;
              me.$message.error("Error! " + error.response.data.message);
              console.log("Err: ", error);
            });
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.loading = false;
      this.loadingTable = false;
    },
    complete(data) {
      this.getData = false;
      this.form.simpleProductInventory = data
    },
    getEmployeds() {
      getEmployed()
        .then(({ data }) => {
          this.employeds = data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addSimpleProductsInventory(data) {
      if (data.simpleProducts && data.simpleProducts.length > 0) {
        const sp = data.simpleProducts;
        this.setSimpleProductsInventory(sp, true)
      }
    },
    async deleteProduct(data) {
      const sp = data.product.product_pivot.map((el) => {
        const id = parseInt(el.product_simple_id);
        const quantity = parseFloat(data.quantity) * parseFloat(el.quantity);
        return { id, quantity };
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.setSimpleProductsInventory(sp, false);
    },
    async setSimpleProductsInventory(sp, sum) {
      const spf = await this.form.simpleProductInventory;
      sp.forEach((el) => {
        const found = spf.findIndex((element) => element.id === el.id);
        if (found !== -1) {
          if (sum) {
            this.form.simpleProductInventory[found].quantity += el.quantity;
          } else {
            this.form.simpleProductInventory[found].quantity -= el.quantity;
            if (this.form.simpleProductInventory[found].quantity <= 0) {
              this.form.simpleProductInventory.splice(found);
            }
          }
        } else {
          // solo se agrega si la propidad 'sum' === true
          if (sum) {
            this.form.simpleProductInventory.push(el);
          }
        }
      });
    },
  },
  created() {
    this.getEmployeds();
  },
};
</script>
<style lang="scss" scope>
.el-select-dropdown__item {
  padding-top: 5px;
  line-height: 20px;
}

.options-product {
  height: 53px;
}
</style>