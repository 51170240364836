<template>
  <div class="step1">
    <div class="content">
      <el-form
        :model="form"
        :rules="rules"
        :label-position="'top'"
        v-loading="loading"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item prop="type_document">
          <label slot="label">
            <i class="el-icon-document"></i> Selecciona un tipo
          </label>
          <el-select
            v-model="form.type_document"
            clearable
            placeholder="Tipo Documento"
            value-key="id"
            @change="setTypeDocument"
          >
            <el-option
              v-for="item in types"
              :key="item.id"
              :label="item.descripcion"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <Transition name="fade">
          <el-form-item prop="return_money" v-if="form.type_document.id == 5">
            <label slot="label">
              <i class="el-icon-money"></i> Devolucion de dinero?
            </label>
            <el-switch
              v-model="form.return_money"
              active-text="Si"
              inactive-text="No"
              @change="setReturnMoney"
            >
            </el-switch>
          </el-form-item>
        </Transition>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getTypesSelected } from "@/api/type_document";
import { mapGetters } from "vuex";
export default {
  name: "step1",
  props: ["data"],
  computed: {
    ...mapGetters(["return_money"]),
  },
  mounted() {
    this.loading = true;
    this.getTypes();
    this.form.return_money = this.return_money
    if (this.data != "") {
      this.form.type_document = this.data;
    }
  },
  data() {
    return {
      types: [],
      loading: false,
      form: {
        type_document: "",
        return_money: false,
      },
      rules: {
        type_document: [
          {
            required: true,
            message: "Por favor, seleccione un tipo",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    getTypes() {
      getTypesSelected({ data: [5, 10] })
        .then(({ data }) => {
          this.types = data;
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          this.$message.error("Oops", "Error");
        });
    },
    setTypeDocument(data) {
      this.$store.commit("SET_REFUND_DATA", null);
      this.$store.commit("SET_REFUND_TYPE", data);
    },
    setReturnMoney() {
      this.$store.commit("SET_RETURN_MONEY", this.form.return_money);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>