import request from '@/utils/request'

export function get() {
  return request({
    url: 'typeDocument/getAll',
    method: 'get'
  });

}
export function getTypesSelected(data) {
  return request({
    url: 'typeDocument/getTypesSelected',
    method: 'post',
    data
  });
}
