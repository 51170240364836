<template lang="html">
  <el-row :gutter="24" class="footerRight">
    <el-col :span="12" class="first" v-if="show">
      <el-button size="mini" :type="(edit) ? 'warning' : 'success'" class="w-100" @click="selectOption(option)">
        <i :class="(edit) ? 'el-icon-edit' : 'el-icon-check'"></i>
        <el-divider direction="vertical"></el-divider>
        {{ (edit) ? 'Actualziar' : 'Guardar' }}
      </el-button>
    </el-col>
    <el-col :span="12" v-if="!edit && show">
      <el-button size="mini"  class="w-100" @click="selectOption('cancel')">
        <i class="el-icon-close"></i>
        <el-divider direction="vertical"></el-divider>
        Cancelar
      </el-button>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "FooterComponent",
  props: ["edit", "show"],
  computed: {
    option() {
      return this.edit ? "update" : "save";
    }
  },
  methods: {
    selectOption(type) {
      this.$store.commit("SET_ACTION_DRAWER", type);
    }
  }
};
</script>

<style lang="css" scoped>
.footerRight {
  position: fixed;
  bottom: 0;
  padding: 10px 20px;
  width: 26.5%;
  margin-right: 0px;
  margin-left: 0px;
  border-top: solid 1px #dcdfe6;
  background-color: white;
  z-index: 3;
}
.first {
  border-right: solid 1px #dcdfe6;
}
</style>
