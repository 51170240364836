<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="50">
        <!-- grid -->
        <el-col :span="12" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="row-table">
          <el-row :gutter="20" class="row-filter">
            <el-col :xs="12" :sm="4" :md="4" :lg="4" :xl="4">
              <el-input size="medium" placeholder="Factura" v-model="invoice" clearable @keyup.native.enter="getAll"
                @clear="
                  invoice = null;
                getAll();
                ">
              </el-input>
            </el-col>
            <el-col :xs="12" :sm="4" :md="4" :lg="4" :xl="4">
              <el-input size="medium" placeholder="Documento cliente" v-model="document" clearable
                @keyup.native.enter="getAll" @clear="
                  document = null;
                getAll();
                ">
              </el-input>
            </el-col>
            <el-col :xs="12" :sm="4" :md="4" :lg="4" :xl="4">
              <el-select size="medium" v-model="type_amortization" value-key="id" filterable
                placeholder="Tipo amortizacion">
                <el-option v-for="item in typeAmortizations" :key="item.id" :label="item.description" :value="item">
                  <template>
                    <i :class="item.icon"></i> <span>{{ item.description }}</span>
                  </template>
                </el-option>
              </el-select>
            </el-col>

            <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
              <el-button type="primary" size="medium" round :loading="loadingBtn" @click="getAll"><i
                  class="el-icon-search"></i>
                Filtrar</el-button>
              <!-- <el-button type="success" size="mini" round @click="openModalForm"><i class="el-icon-plus"></i>
                Nuevo</el-button> -->
              <!-- <el-tooltip class="item" effect="dark" content="Descargar productos" placement="top">
                <el-button type="success" size="mini" round :loading="loadingDownload" @click="downloadProducts"><i
                    class="el-icon-download"></i>
                </el-button>
              </el-tooltip> -->
            </el-col>
          </el-row>

          <!-- table -->
          <el-table :data="tableData" :default-sort="{ prop: 'document.fecha', order: 'descending' }"
            v-loading="loadingTable" style="width: 100%; margin-top: 20px" height="400" size="samll">
            <div slot="empty">
              <el-empty description="No hay datos"></el-empty>
            </div>
            <el-table-column label="Fecha" prop="document.fecha" sortable width="120">
              <template slot-scope="scope">
                <div><strong><i class="el-icon-date"></i> {{ scope.row.document.fecha }}</strong></div>
              </template>
            </el-table-column>
            <el-table-column label="Consecutivo" prop="document.consecutivo" sortable width="130">
              <template slot-scope="scope">
                <div style="font-size: 20px;"><strong>{{ scope.row.document.consecutivo }}</strong></div>
                <small>{{ scope.row.document.type.type_pivot.document_name }}</small>
              </template>
            </el-table-column>
            <el-table-column label="Cliente" prop="document.person.nombre" sortable>
              <template slot-scope="scope">
                <div><strong><i class="el-icon-user"></i> {{ scope.row.document.person.nombre }}</strong></div>
                <small><i class="el-icon-postcard"></i> {{ scope.row.document.person.documento }}</small><br>
                <small><i class="el-icon-phone-outline"></i> {{ scope.row.document.person.telefono }}</small>
              </template>
            </el-table-column>
            <el-table-column label="Forma de pago" prop="payment.descripcion" sortable>
              <template slot-scope="scope">
                <div>{{ scope.row.payment.descripcion }}</div>
              </template>
            </el-table-column>
            <el-table-column label="Credito" prop="valor" sortable width="130">
              <template slot-scope="scope">
                <div class="credit">$ {{ formatNumber(scope.row.valor) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="Pago actual" prop="total_pay" sortable width="130">
              <template slot-scope="scope">
                <div class="total_pay">$ {{ formatNumber(scope.row.total_pay) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="Debe" prop="difference" sortable width="130">
              <template slot-scope="scope">
                <div class="difference">$ {{ formatNumber(scope.row.difference) }}</div>
              </template>
            </el-table-column>

            <el-table-column label="Acciones" width="125">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="Crear abono" placement="top">
                  <el-button v-if="paid_credit_select === 0" size="mini" type="success"
                    @click="handleCreate(scope.$index, scope.row)" icon="el-icon-document-add" circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Listar abonos" placement="top">
                  <el-button size="mini" type="primary" @click="handleList(scope.$index, scope.row)" icon="el-icon-files"
                    circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Imprimir pagos" placement="top">
                  <el-button size="mini" type="default" @click="paidCreditsPrintMessage(scope.row)" icon="el-icon-printer"
                    circle></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <div v-if="tableData.length > 0" class="pagination-box animated fadeInRight"
            style="text-align: end; padding-top: 10px">
            <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
              @current-change="handleCurrentPage"></el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <modal-form :openModal="openModal" :dataForm="dataForm" :edit="edit"
      @closeModal="closeModal($event, true)"></modal-form>

    <modal-list :openModalLi="openModalList" :dataFormList="dataFormList"
      @closeModalList="closeModalList($event, true)"></modal-list>
  </div>
</template>

<script>
const axios = require('axios');
import { mapGetters } from "vuex";
import * as qz from "qz-tray";
import ModalForm from "./ModalForm.vue";
import ModalList from "./ModalList.vue";
import { getUser, getDataPos, getPrincipalBranch } from "@/utils/auth";
import {
  getDocumentsWithCredits,
  deleteData,
  getAll
} from "@/api/amortization";
import { formatInt, formatNumber } from "@/utils/number";
import { actualDate } from "@/utils/date";
export default {
  name: "Amortization",
  components: {
    ModalForm, ModalList
  },
  computed: {
    ...mapGetters([
      "printer_default",
      "printerReConnect",
    ]),
  },
  watch: {
    printerReConnect(val) {
      if (val) {
        this.startConnectionPrint();
      }
    },
  },
  data() {
    return {
      user: getUser(),
      dataPos: getDataPos(),
      dataPrincipalBranch: getPrincipalBranch(),
      pathImagesUrl: process.env.VUE_APP_ROOT,
      urlImage: process.env.VUE_APP_ROOT + "static/images/uploads/",
      urlApi: process.env.VUE_APP_ROOT_API,
      url: process.env.VUE_APP_ROOT,
      loadingTable: false,
      loadingBtn: false,
      edit: false,
      search: "",
      tableData: [],
      typeAmortizations: [
        { id: 1, description: 'Sin pagar', paid_credit: 0, icon: 'el-icon-money' },
        { id: 2, description: 'Pagado', paid_credit: 1, icon: 'el-icon-check' },
      ],
      paid_credit_select: 1,
      dataPaidCredits: null,
      infoCredit: null,
      // print
      creditResume: null,
      paidCredits: "",
      //
      pageSize: 20,
      currentPage: 1,
      total_rows: 0,
      invoice: null,
      document: null,
      type_amortization: { id: 1, description: 'Sin pagar', paid_credit: 0, icon: 'el-icon-money' },
      // modal form
      openModal: false,
      openModalList: false,
      dataForm: {},
      dataFormList: {},
    };
  },
  methods: {
    paidCreditsPrintMessage(row) {
      let me = this
      this.$confirm('Desea imprimir los pagos de este crédito?', 'Atención', {
        confirmButtonText: 'Si',
        cancelButtonText: 'No, Cancelar',
        type: 'warning'
      }).then(() => {
        me.getDataPaidCredits(row)
        this.$message({
          type: 'success',
          message: 'Documento enviado a imprimir'
        });
      }).catch((error) => {
        console.log(error);
        this.$message({
          type: 'info',
          message: 'Impresión cancelada'
        });
      });
    },
    async getDataPaidCredits(row) {
      let me = this
      this.infoCredit = row
      await getAll(100, 1, row.document.consecutivo)
        .then(async ({ data }) => {
          this.dataPaidCredits = data.data;
          this.paidCredits = '<tr>' +
            "<th>Fecha de abono</th>" +
            "<th># Factura</th>" +
            "<th>Valor abono</th>" +
            '</tr>';
          if (me.dataPaidCredits.length > 0) {
            await me.dataPaidCredits.forEach(function (el) {
              me.paidCredits +=
                '<tr>' +
                '<td style="text-align:center;">' + el.credit_date + '</td>' +
                '<td style="text-align:center;">' + el.document.consecutivo + '</td>' +
                '<td style="text-align:center;">' +
                "$ " + formatNumber(parseFloat(el.credit_value)) + "</td>" +
                "</tr>";
            });
          }else{
            me.paidCredits += "<tr><td colspan='3' style='text-align:center;padding-top:30px;padding-bottom:30px;'>NO HAY PAGOS REALIZADOS</td></tr>";
          }
          await this.startPrint()
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
          this.$message.error("Oops", "Error.");
        });
    },
    // print
    async startPrint() {
      let me = this;
      if (me.printer_default !== "") {
        if (!qz.websocket.isActive()) {
          me.startConnectionPrint();
        }
        setTimeout(() => {
          me.sendPrinter(me.printer_default);
        }, 300);
      } else {
        if (qz.websocket.isActive()) {
          qz.printers
            .getDefault()
            .then(function (data) {
              if (!data) {
                this.$swal.fire({
                  icon: "warning",
                  title: "Atención!",
                  text: "No hay una impresora predeterminada!",
                });
              } else {
                me.$store.commit("SET_PRINTER", data);
                me.startPrint();
              }
            })
            .catch(function (error) {
              console.log("error: ", error);
            });
        }
      }
    },
    sendPrinter(printer) {
      let me = this;
      var config = qz.configs.create(printer, { scaleContent: false }); // Create a default config for the found printer
      var document_text = "PAGO DE CRÉDITO";
      var documentHead =
        '<div style="text-align:center;">' +
        // '<img style="width:150px" src="' +
        // this.pathImagesUrl +
        // 'static/images/logo.png" /> </br>' +
        this.dataPrincipalBranch.razon_social +
        "</div>" +
        '<div style="text-align:center;">NIT: ' +
        this.dataPrincipalBranch.nit +
        "</div>" +
        '<div style="text-align:center;">Caja: ' +
        this.dataPos.computer.name +
        "</div>" +
        '<div style="margin: 10px 0 10px 0;"></div>' +
        '<div style="text-align:center;">' +
        this.user.branch.razon_social +
        "</div>" +
        '<div style="text-align:center;">' +
        this.user.branch.direccion +
        "</div>" +
        '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
        // invoice
        '<div style="text-align:center;">' +
        document_text + " (Factura: " + this.infoCredit.document.consecutivo + ")" +
        "</div>" +
        '<div style="text-align:center;">' +
        actualDate() +
        "</div>";

      var documentFooter =
        '<div style="font-size:10px;text-align:center;margin-top:20px;margin-bottom:10px;">' +
        '<div style="font-size:10px;text-align:center;margin-top:20px;">' +
        "</div>" +
        '<div style="font-size:15px;text-align:center;margin-top:20px;font-weight: 900;">**** ' + document_text + ' ****</div>' +
        '<div style="text-align:center;margin-top:2px;margin-bottom:2px;font-size:9px">Desarrollado por aplicacionesexcel.com</div>';


      var printData = [
        {
          type: "pixel",
          format: "html",
          flavor: "plain",
          data:
            "<html>" +
            '<body style="font-size:11px;">' +
            documentHead +
            '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
            // client
            '<div style="padding-left:10px;font-size:15px;">Cliente: ' +
            this.infoCredit.document.person.nombre +
            "</div>" +
            '<div style="padding-left:10px;font-size:15px;"># Documento: ' +
            this.infoCredit.document.person.documento +
            "</div>" +
            '<div style="padding-left:10px;font-size:15px;">Teléfono: ' +
            ((this.infoCredit.document.person.telefono !== null) ? this.infoCredit.document.person.telefono : '') +
            "</div>" +
            '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
            // table resumen
            '<div style="margin-bottom: 10px;font-size:18px;text-align:center;font-weight:bold;">Resumen del crédito</div>' +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            '<table style="width:100%;font-size:13px;">' +
            '<tr>' +
            '<td style="padding-left:10px;text-align:center;">Valor del crédito: <strong>$' + formatNumber(parseFloat(this.infoCredit.valor)) + '</strong></td>' +
            '<td style="text-align:center;">Pago actual: <strong>$' + formatNumber(parseFloat(this.infoCredit.total_pay)) + "</strong></td>" +
            '<td style="text-align:center;">Deuda a la fecha: <strong>$' + formatNumber(parseFloat(this.infoCredit.difference)) + "</strong></td>" +
            '</tr>' +
            "</table>" +
            '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
            // table detalle
            '<div style=";margin-bottom: 10px;margin-top: 30px;font-size:18px;text-align:center;font-weight:bold;">Pagos realizados</div>' +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            '<table style="width:100%;font-size:13px;">' +
            this.paidCredits +
            "</table>" +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            documentFooter +
            '<div style="margin: 20px 0 20px 0;border-style: dashed; border-width: 1px;"></div>' +
            "</div>" +
            "</body>" +
            "</html>",
        },
      ];
      qz.print(config, printData)
        .then(function () {
          me.$store.commit("SET_ID_DOCUMENT", "");
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },

    closeModalList(data) {
      this.openModalList = false
      this.dataFormList = {}
      if (data && data.open) {
        this.openModalForm(data.data)
      }
    },
    closeModal(data) {
      this.openModal = false
      this.edit = false
      this.dataForm = {}
      console.log(data);
      if (data) {
        if (data.refreshTable) {
          this.getAll()
        }
        if (typeof data != 'undefined' && data.open) {
          this.openModalLi(data.data)
        }
      }
    },
    openModalForm(data) {
      this.openModal = true
      if (typeof data != 'undefined') {
        this.dataForm = data
      }
    },
    openModalLi(data) {
      this.openModalList = true
      if (typeof data != 'undefined') {
        this.dataFormList = data
      }
    },
    // async validateIntCode(code) {
    //   let exist = false;
    //   await validateCodeExist(code)
    //     .then(({ data }) => {
    //       console.log("datos", data);
    //       exist = data ? true : false;
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    //   return exist;
    // },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getAll();
    },
    getAll(resetCurrenPage) {
      this.loadingTable = true;
      this.loadingBtn = true;
      if (typeof resetCurrenPage != 'undefined' && resetCurrenPage) {
        this.currentPage = 1;
      }
      getDocumentsWithCredits(
        this.pageSize,
        this.currentPage,
        this.invoice,
        this.document,
        this.type_amortization.paid_credit
      )
        .then(({ data }) => {
          let datas = data.data
          this.tableData = datas.data;
          this.total_rows = datas.total;
          this.loadingTable = false;
          this.loadingBtn = false;
          this.paid_credit_select = this.type_amortization.paid_credit;
        })
        .catch(function (error) {
          this.loadingBtn = false;
          console.log(error);
        });
    },
    handleCreate(index, row) {
      this.dataForm = row
      this.edit = false
      this.openModal = true
    },
    handleList(index, row) {
      this.dataFormList = row
      this.openModalList = true
    },
    handleDelete(index, row) {
      var me = this;
      this.loadingTable = true;
      this.$swal
        .fire({
          title: "<strong>Atención!</strong>",
          icon: "warning",
          html: "Deseas borrar este pago?",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          focusConfirm: false,
          confirmButtonText:
            '<i class="mdi mdi-clipboard-check-outline"></i> Si',
          cancelButtonText:
            '<i class="mdi mdi-close-circle-outline"></i> Cancelar',
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteData(row.id)
              .then(({ data }) => {
                if (data.code === 200) {
                  this.getAll();
                  setTimeout(() => {
                    me.loadingTable = false;
                  }, 500);
                } else {
                  this.$message.error("Error! " + data.error.errorInfo[2]);
                }
              })
              .catch(function (error) {
                this.loadingTable = false;
                console.log("Err: ", error);
              });
          } else {
            me.loadingTable = false;
          }
        });
    },
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(formatInt(number));
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },

    // start connection print
    startConnectionPrint() {
      let me = this;
      setTimeout(() => {
        // Authentication setup ///
        var privateKey =
          "-----BEGIN PRIVATE KEY-----\n" +
          "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCn13RTAjw4IfTw\n" +
          "d46mk3Lw7YLFJbIGdZvV4ieg51+fqqyUk81+q0icBIAnsrg3j2J+oBlwd1HLgaeI\n" +
          "C+8tEVn3ed9tzvxhJ5iGmd9DUorPIP2RsX+62uKi7OV4fXYBkqSXKWdaoI0nLEwf\n" +
          "FkCL7b8UN3LUazECIsK4bXeYIFMgAERYr6lvtP8HTJ3t6d/W0BWNQgXpUMuGJhvo\n" +
          "5yiJhsVZp8YjF/xyBcQRZjx58Ze4n426dM4lT5cH7EDJk5iogL/9D5YmPZ8dFMVZ\n" +
          "yaAfVVvT1x3vC7GqboKbYISNZLyVM3/+F3ikkcGe/NLbKUwdnFc+rKOdGp/Dup8T\n" +
          "HWVpm9MfAgMBAAECggEAGV2Z8mudjzwuiOMAt5MEJX43q7TZNVmz3fyqUYp2LKq+\n" +
          "H0xBG7omJJIXjihX5+mvEIUAozsdLPzgxVJMaMq9BoYwKqfEA6jYx8rcb4+7c7dA\n" +
          "KzMgoywuYUIdIfsQCQfRARlcSdmGy5tJu88AnUUmghcpLwouI2z+caH1l5OXDHhP\n" +
          "LbN+opDi0EhCd+PoNInkpj9s12/tl8rOOlHJSGUSgIAZGyafS5llBm3JoO4dZUmQ\n" +
          "0Lq1tKI25ai7B7EtZ+3Dyvya6N+5ACXucwlsufROc4kLrGbAffEPkreHydD78iSf\n" +
          "d1VMUuDABnUawPOLnd3yKBwAfvxN6jhjlYThw7rNRQKBgQDVX17U7H+IZsbvbknT\n" +
          "XsJQJlE+fdUYI6EImBDs9MG34akpTi+MdgdKJOKTJIVQIm5pxOD1nxzrY1vlNRML\n" +
          "hA8o0ZACcsION/476puRfG2LhxVak+gN+P0NlWXY4R1QsLSsc/KaL1MoBO2lom7+\n" +
          "C9l+KIG544ZzfK/hirVhPKQQZQKBgQDJX3iMS37VLu+2OQ26L9xstH33C9R73DJJ\n" +
          "mpPXra1KA1hgPYETy7769LMdYV7vKsyh4++4VgWAmSLkOK0H/ViQpmcW+kyyLQ9w\n" +
          "euD87zRcjIIY0ehI8JyLHX9Dxcuy4/oJDn+0IRXXkHDfSrw6kfkFuP+khTSRbbAj\n" +
          "q0L7vyfjMwKBgCwC5f6pYYzzFimg6B1vOElr/eoT7nZyMu8g3qzht2uEYm4gsHIa\n" +
          "jlpezVVofQ8QaxHqCDThwwdSkUvv7KO9c5sJztiSbzCboBcfx0L1FWtoS6FJTN5E\n" +
          "831vyXg4xuEz8bB4ohvnkxF4IsS9goNoUk5RcKC1K7B6kZTGFa+e4IB5AoGAccGK\n" +
          "+cYUKh3PsKLeTFWJMadsmUiHikjD56x45Bfqkqr9G7Di3+nzQxVEsc41sOn+m12G\n" +
          "KB4AEbtwaHhT1vWZOG2oQuS2utiVpjbkLj561+VQAPFjRQEXjcbmA8US40XCE6er\n" +
          "IHioPBQOjzVKy9N2V3NAhxHwtk6+eAfhWRGNWN0CgYA6XAiF1Al+kYkosSKsjpxo\n" +
          "BdKzT+5vCw37Iq3F+DYxzRb8MKx9XpeGoEWbG62Xez7eZ2B6UaARGDu43Ioa/6vk\n" +
          "bh9647T6nehoGlagzdu92kO5FCFsoIB2pBBZAS5k7S8jtSS7To2W3FDY/o3wwIRg\n" +
          "spuV+871lajMrXi3/faa/w==\n" +
          "-----END PRIVATE KEY-----";

        qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
        qz.security.setSignaturePromise(function (toSign) {
          return function (resolve, reject) {
            try {
              var pk = KEYUTIL.getKey(privateKey);
              var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
              sig.init(pk);
              sig.updateString(toSign);
              var hex = sig.sign();
              // console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
              resolve(stob64(hextorstr(hex)));
            } catch (err) {
              console.error(err);
              reject(err);
            }
          };
        });
        qz.security.setCertificatePromise(function (resolve, reject) {
          resolve(axios.get(process.env.VUE_APP_ROOT_API + '/certs/getQzCertificate').then(response => response.data))
        });
        if (!qz.websocket.isActive()) {
          qz.websocket.connect().then(function () {
            console.log("Connected QZ-tray! ", qz.websocket.isActive());
            me.$store.commit("SET_PRINTER_CONNECT", true);
            me.$store.commit("SET_PRINTER_RECONNECT", false);
            qz.printers
              .getDefault()
              .then(function (data) {
                me.$store.commit("SET_PRINTER", data);
              })
              .catch(function (error) {
                console.log("error: ", error);
              });
          });
        } else {
          me.$message({
            message: "La conección con la impresora, ya ha sido establecida.",
            type: "success",
          });
          me.$store.commit("SET_PRINTER_CONNECT", true);
          me.$store.commit("SET_PRINTER_RECONNECT", false);
        }
      }, 2000);
    },
  },
  created() {
    this.startConnectionPrint();
    this.getAll();
  },
};
</script>

<style lang="scss" scope>
.credit,
.total_pay,
.difference {
  font-weight: bold;
}

.total_pay {
  color: rgb(30, 179, 38);
}

.difference {
  color: rgb(228, 37, 37);
}

.el-alert {
  padding: 2px 16px !important;
}


@media (max-width: 990px) {
  .row-table {
    margin-top: 50px;
  }
}

@media (max-width: 766px) {
  .row-filter {
    .el-col {
      margin-top: 10px;
    }
  }
}
</style>