<template>
  <el-drawer
    :visible.sync="open_drawer"
    :before-close="handleClose"
    :destroy-on-close="true"
    :append-to-body="true"
    :wrapperClosable="false"
    direction="rtl"
    size="30%"
  >
    <header-component slot="title" :data="data" @back="handleClose()" />
    <transition-group name="fade">
      <component
        :is="data.component_active"
        :payload="data"
        key="component"
      ></component>
      <footer-component key="footer" :show="data.show_btn" :edit="data.edit" />
    </transition-group>
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import FooterComponent from "./Footer";
import HeaderComponent from "./Header";
// components
import Upf from "@/views/apps/pos/reports/Upf.vue";
export default {
  data() {
    return {
      // open_drawer: false,
    };
  },
  computed: {
    ...mapGetters(["open_drawer", "action_drawer", "data"]),
  },
  watch: {
    open_drawer(val) {
      console.log("el drawer: ", val);
    },
    action_drawer(val) {
      console.log("action drawer: ", val);
    },
  },
  methods: {
    handleClose(done) {
      this.$store.commit("SET_DATA", {
        component_active: ''
      });
      this.$store.commit("SET_OPEN_DRAWER", false);
    },
  },
  components: {
    FooterComponent,
    HeaderComponent,
    Upf,
  },
};
</script>

<style lang="css">
.el-drawer__body {
  margin-top: 80px;
  margin-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
}
.el-drawer {
  overflow: auto !important;
}

.el-drawer__close-btn {
  margin-right: 40px;
}
.el-drawer__header {
  padding-top: 5px !important;
  background-color: #5f8fdf !important;
  color: white;
  position: fixed;
  top: 0;
  width: 28%;
  margin-right: 0px;
  margin-left: 0px;
  z-index: 999;
}
.el-page-header__content {
  color: white !important;
}

@media (max-width: 1260px) {
  .el-drawer,
  .el-drawer__header {
    width: 40% !important;
  }
  .footerRight {
    width: 38.5% !important;
  }
}
@media (max-width: 920px) {
  .el-drawer,
  .el-drawer__header {
    width: 50% !important;
  }
  .footerRight {
    width: 48.5% !important;
  }
}
@media (max-width: 700px) {
  .el-drawer,
  .el-drawer__header {
    width: 70% !important;
  }
  .footerRight {
    width: 68.5% !important;
  }
}
@media (max-width: 490px) {
  .el-drawer,
  .el-drawer__header {
    width: 90% !important;
  }
  .footerRight {
    width: 88.5% !important;
  }
}
</style>