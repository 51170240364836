import request from '@/utils/request'

export function store(data) {
  return request({
    url: 'product/store',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: 'product/update',
    method: 'put',
    data
  })
}

export function deleteData(id) {
  return request({
    url: 'product/destroy/' + id,
    method: 'delete'
  });
}

export function getAll(pageSize, currentPage, codeInt, categoryId, description) {
  return request({
    url: 'product/getAll/' + pageSize + '/' + codeInt + '/' + categoryId + '/' + description + '?page=' + currentPage,
    method: 'get'
  });
  
}

export function getById(id) {
  return request({
    url: 'product/getById/' + id,
    method: 'get'
  });
}

export function getProductByCode(code, store) {
  return request({
    url: 'product/getProductByCode/' + code + '/' + store,
    method: 'get'
  });
}

export function uploadImage(data) {
  return request({
    url: 'product/uploadImage',
    method: 'post',
    data
  });
}

export function getServices() {
  return request({
    url: 'product/getServices',
    method: 'get'
  });
}

export function getAllTax() {
  return request({
    url: 'product/getAllTax',
    method: 'get'
  });
}

export function searchProductByData(data) {
  return request({
    url: 'product/searchProductByData',
    method: 'post',
    data
  });
}

export function validateCodeExist(code) {
  return request({
    url: 'product/validateCodeExist/' + code,
    method: 'get'
  });
}
export function deleteProductPivotById(id) {
  return request({
    url: 'product/deleteProductPivotById/' + id,
    method: 'delete'
  });
}

export function downloadProducts(data) {
  return request({
    url: 'product/downloadProducts',
    method: 'post',
    data,
    responseType: 'blob'
  });
}