import request from '@/utils/request'

const model = 'amortization';

export function getAll(page_size, currentPage, invoice) {
  return request({
    url: model +  '/getAll/' + page_size +'/' +invoice + '?page=' + currentPage,
    method: 'get'
  })
}

export function getDocumentsWithCredits(page_size, currentPage, invoice, document, type_amortization) {
  return request({
    url: model +  '/getDocumentsWithCredits/' + page_size +'/' + type_amortization + '/' + invoice + '/' + document + '?page=' + currentPage,
    method: 'get'
  })
}

export function store(data) {
    return request({
      url: model +  '/store',
      method: 'post',
      data
    })
  }
  
  export function update(data) {
    return request({
      url: model +  '/update',
      method: 'put',
      data
    })
  }

  export function deleteData(id) {
    return request({
      url: model +  '/destroy',
      method: 'delete',
      data: id
    })
  }


export function getById(id) {
  return request({
    url: model +  '/getById/' + id,
    method: 'get'
  })
}