<template>
  <div>
    <el-row>
      <el-col :span="12">
        <h2 style="margin-top: 0px;">Datos de registro</h2>
      </el-col>
      <el-col :span="12">
        <el-button class="fr" @click="showModal()" icon="el-icon-document">Listar documentos
        </el-button>
      </el-col>
    </el-row>

    <el-dialog width="70%" :visible.sync="openModal" :before-close="closeModal" :append-to-body="true"
      :destroy-on-close="true" :close-on-click-modal="false" top="30px">
      <div slot="title">
        <i class="mdi mdi-file-search-outline"></i> Listado de {{ $route.name }}
      </div>
      <el-card class="card-shadow--small" shadow="never">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-input placeholder="Número de documento" size="mini" v-model="consecutive" clearable
              @clear="consecutive = null">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-col>
          <el-col :span="8">
            <el-date-picker v-model="date" type="date" size="mini" placeholder="Fecha" format="yyyy/MM/dd"
              value-format="yyyy-MM-dd" @clear="date = null">
            </el-date-picker>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" icon="el-icon-search" size="mini" :loading="loadingFilter"
              @click="filterTable">Buscar</el-button>
          </el-col>
        </el-row>
      </el-card>
      <el-table :data="tableData" style="width: 100%" height="280" size="medium" v-loading="loading"
        element-loading-text="Cargando Datos..." element-loading-spinner="el-icon-loading"
        element-loading-background="rgb(246 246 246 / 80%)">
        <el-table-column prop="consecutivo" label="Consecutivo" width="150">
          <template slot-scope="scope">
            <div style="font-size: 20px;"><strong>{{ scope.row.consecutivo }}</strong></div>
            <el-tag type="danger" size="mini" v-if="scope.row.anulado == 1">Anulado</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="fecha" label="Fecha"></el-table-column>
        <el-table-column label="Cantidad de productos" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.type.id === 8">{{ scope.row.detail.length }}</div>
            <div v-if="scope.row.type.id !== 8">{{ scope.row.detail_with_out_associated_product.length }}</div>
          </template>
        </el-table-column>
        <el-table-column align="right" label="Acciones" width="200">
          <template slot-scope="scope" v-if="scope.row.anulado != 1">
            <el-tooltip class="item" effect="dark" content="Anular" placement="top">
            <el-button size="mini" type="primary" @click="handleView(scope.$index, scope.row)" circle>
              <i class="el-icon-view"></i>
            </el-button>
          </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Anular" placement="top" v-can="'admin'">
              <el-button size="mini" type="danger" @click="handleDelete(scope.row)" circle>
                <i class="el-icon-delete"></i>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="tableData.length > 0" class="pagination-box" style="text-align: end; padding-top: 10px">
        <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
          @current-change="handleCurrentPage"></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal()" icon="el-icon-close" size="small">Cerrar</el-button>
      </span>

      <!-- MODAL TWO  -->
      <modal-list-document-detail :openModalTwo="openModalTwo" :documentId="documentId"
        :consecutive="consecutiveModalTwo" :transaction="transaction" @close="closeModalTwo"></modal-list-document-detail>

    </el-dialog>
  </div>
</template>
<script>
import ModalListDocumentDetail from './ModalListDocumentDetail.vue';
import { getDocuments, anulateDocument } from "@/api/document";
import { getUser } from "@/utils/auth";
export default {
  name: "ModalListDocument",
  props: ["typeDocument", "transaction"],
  components:{
    ModalListDocumentDetail
  },
  data() {
    return {
      loading: false,
      loadingFilter: false,
      loadingClient: false,
      openModal: false,
      openModalTwo: false,
      user: getUser(),
      tableData: [],
      options: [],
      // Pagination
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
      // Filters
      consecutive: null,
      date: null,
      client: null,
      // modal two
      documentId: '',
      consecutiveModalTwo: '',
    };
  },
  methods: {
    handleDelete(row) {
      let me =this
      this.$confirm('Deseas anular el documento ' + row.consecutivo + '?', 'Atención', {
        confirmButtonText: 'Si, anular',
        cancelButtonText: 'Cancelar',
        confirmButtonClass: 'el-button--danger',
        type: 'warning'
      }).then(() => {
        me.deleteDocument(row)
      })
    },
    deleteDocument(row) {
      this.loading = true
      anulateDocument({id_document: row.id, concept: 'Anulado desde ' + row.type.descripcion})
        .then(({ data }) => {
          this.getDocuments()
          this.$message({
            type: 'success',
            message: 'Documento eliminado anulado'
          });
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
          this.$message.error("Oops", "Error.");
        });
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getDocuments();
    },
    filterTable() {
      this.loadingFilter = true;
      this.getDocuments();
    },
    showModal() {
      this.openModal = true
      this.getDocuments()
    },
    getDocuments() {
      this.loading = true;
      if (
        this.consecutive != null ||
        this.date != null
      ) {
        this.currentPage = 1;
      }
      getDocuments(this.pageSize,
        this.currentPage,
        [this.typeDocument],
        this.consecutive,
        this.date,
        this.client,
        this.user.branch.id)
        .then(({ data }) => {
          this.loading = false;
          this.loadingFilter = false;
          this.total_rows = data.total;
          this.tableData = data.data;
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
          this.loadingFilter = false;
          this.$message.error("Oops", "Error.");
        });
    },
    handleView(index, row) {
      this.openModalTwo = true
      this.documentId = row.id
      this.consecutiveModalTwo = row.consecutivo
    },
    closeModal() {
      this.openModal = false
      this.tableData = []
    },
    closeModalTwo() {
      this.documentId = ''
      this.openModalTwo = false
    },
  },
};
</script>

<style lang="scss"></style>