export function roundToTheNearestAnything(value, roundTo) {
  if(typeof roundTo === 'undefined'){
    roundTo = 50
  }
  var mod = value % roundTo;
  if (mod < (roundTo / 2)) {
   return  value + (-mod);
  }else {
   return value + (roundTo - mod);
  }
}

export function formatNumber(number) {
  //PASAR INTEGER A FORMATO NUMERO
  return new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(
    number && number != "" ? number : 0
  );
}

export function formatInt(number) {
  // PASAR FORMATO NUMERO A INTEGER
  return number == null || number == ""
    ? 0
    : parseFloat(_.replace(number.toString(), /\./g, ""));
}

export function formatFloat(number) {
  // PASAR FORMATO NUMERO A INTEGER
  return number == null || number == ""
    ? 0
    : parseFloat(number);
}
