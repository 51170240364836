<template>
  <div class="page-invoice scrollable">
    <div class="toolbar">
      <el-date-picker
        v-model="dateReport"
        size="small"
        type="date"
        placeholder="Pick a Date"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        class="date-report animated fadeInRight"
      >
      </el-date-picker>
      <el-button
        class="animated fadeInRight"
        type="primary"
        size="small"
        @click="searchReportByBranch"
        :loading="loadingSearch"
        ><i class="mdi mdi-file-search-outline"></i> Buscar</el-button
      >
      <el-button
        class="animated fadeInRight"
        type="success"
        size="small"
        @click="saveReport"
        ><i class="mdi mdi-content-save"></i> Guardar</el-button
      >
      <el-button class="animated fadeInRight" plain size="small" @click="print"
        ><i class="mdi mdi-printer"></i> Imprimir informe diario</el-button
      >
    </div>

    <div
      class="invoice bg-white black-text"
      :class="(demo) ? 'demo' : ''"
      v-loading="loading"
      element-loading-text="Cargando información..."
    >
      <div class="invoice-top">
        <div class="info">
          <h2>{{ principalStore.razon_social }}</h2>
          <p>NIT: {{ principalStore.nit }}</p>
        </div>
        <!--End Info-->
        <div class="title">
          <h1>Comprobante #{{ baseCash.cuadre }}</h1>
          <p>Fecha: {{ date }}</p>
          <p>Tienda: {{ user.branch.razon_social }}</p>
        </div>
        <!--End Title-->
      </div>
      <!--End InvoiceTop-->

      <hr />

      <div class="invoice-middle">
        <div class="info">
          <h2>Computadores:</h2>
          <p v-for="item in computers" v-bind:key="item.id">
            {{ JSON.parse(item.computer).name }} -
            {{ JSON.parse(item.computer).code }}
          </p>
        </div>

        <div class="project">
          <h2>Transacciones</h2>
          <p>
            Desde: <strong>{{ initial_consecutive }}</strong> Hasta:
            <strong>{{ final_consecutive }}</strong>
          </p>
        </div>
      </div>
      <!--End Invoice Mid-->

      <div class="invoice-bottom2">
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Factura</th>
              <th>Venta</th>
              <th>IVA</th>
              <th>IPC</th>
              <th>Descuento</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in netSales" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td># {{ item.invoice }}</td>
              <td>$ {{ formatNumber(item.sale) }}</td>
              <td>$ {{ formatNumber(item.iva) }}</td>
              <td>$ {{ formatNumber(roundNumber(item.ipc)) }}</td>
              <td>$ {{ formatNumber(roundNumber(item.discount)) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="total" colspan="2">Total</td>
              <td class="totals">$ {{ formatNumber(totalSales) }}</td>
              <td class="totals">$ {{ formatNumber(totalIva) }}</td>
              <td class="totals">
                $ {{ formatNumber(roundNumber(totalIpc)) }}
              </td>
              <td class="totals">
                $ {{ formatNumber(roundNumber(totalDiscount)) }}
              </td>
            </tr>
          </tfoot>
        </table>

        <table class="summary">
          <thead>
            <th style="width: 40%">&nbsp;</th>
            <th>Ventas</th>
            <th>IVA</th>
            <th>Impuesto al consumo bolsas</th>
          </thead>
          <tbody>
            <tr v-for="item in summary" :key="item.id">
              <td>
                <span style="margin-right: 30px">{{ item.title }}</span>
              </td>
              <td class="summary_values">$ {{ formatNumber(item.sale) }}</td>
              <td class="summary_values">$ {{ formatNumber(item.iva) }}</td>
              <td class="summary_values">
                $ {{ formatNumber(roundNumber(item.ipc)) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="invoice-middle">
        <div class="info">
          <h2>Ventas por dispositivo</h2>
        </div>
      </div>
      <div class="invoice-bottom2">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Código</th>
              <th>Transacciones</th>
              <th>Total venta</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in computers" :key="item.id">
              <td>{{ JSON.parse(item.computer).name }}</td>
              <td>{{ JSON.parse(item.computer).code }}</td>
              <td>{{ item.invoices }}</td>
              <td>$ {{ formatNumber(roundNumber(item.sale)) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="invoice-middle">
        <div class="info">
          <h2>Medios de pago</h2>
        </div>
      </div>
      <div class="invoice-bottom2">
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Nombre</th>
              <th>Transacciones</th>
              <th>Total venta</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in payments" :key="item.name">
              <td>{{ index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.transactions }}</td>
              <td>$ {{ formatNumber(item.value - item.refund) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="invoice-middle">
        <div class="total_document">
          <el-row>
            <el-col :span="12" class="text">TOTAL</el-col>
            <el-col :span="12" class="value"
              >$ {{ formatNumber(totalDocument) }}</el-col
            >
          </el-row>
        </div>
      </div>

      <!--End Invoice-->
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import { mapGetters } from "vuex";
import * as qz from "qz-tray";
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
} from "@/utils/number";
import { searchReport } from "@/api/dailyReport";
import { getById, getBaseCash } from "@/api/branch";
import { getUser, getDemo } from "@/utils/auth";
import { actualDate } from "@/utils/date";
export default {
  name: "DailyReport",
  computed: {
    ...mapGetters(["printer_default", "printerReConnect"]),
  },
  data() {
    return {
      demo: getDemo(),
      user: getUser(),
      date: actualDate(),
      loading: false,
      loadingSearch: false,
      dateReport: null,
      principalStore: "",
      baseCash: "",
      computers: [],
      initial_consecutive: "",
      final_consecutive: "",
      netSales: [],
      payments: [],
      summary: [
        {
          id: 1,
          title: "Ventas Excluidas",
          sale: 0,
          iva: 0,
          ipc: 0,
        },
        {
          id: 2,
          title: "Ventas Exentas",
          sale: 0,
          iva: 0,
          ipc: 0,
        },
        {
          id: 3,
          title: "Ventas Gravadas al 5%",
          sale: 0,
          iva: 0,
          ipc: 0,
        },
        {
          id: 4,
          title: "Ventas Gravadas al 19%",
          sale: 0,
          iva: 0,
          ipc: 0,
        },
      ],
      totalSales: 0,
      totalIva: 0,
      totalIpc: 0,
      totalDiscount: 0,
      totalDocument: 0,
      // data print
      computerText: "",
      salesText: "",
      computerSalesText: "",
      paymentsText: "",
      summaryText: "",
    };
  },
  created() {
    this.startConnectionPrint();
    const o_date = new Intl.DateTimeFormat();
    const f_date = (m_ca, m_it) => Object({ ...m_ca, [m_it.type]: m_it.value });
    const m_date = o_date.formatToParts().reduce(f_date, {});
    this.dateReport = m_date.year + "-" + m_date.month + "-" + m_date.day;
    this.getPrincipalStore();
    // this.searchReportByBranch();
  },
  methods: {
    searchReportByBranch() {
      let me = this;
      this.loadingSearch = true;
      this.loading = true;
      this.computerText = "";
      this.computerSalesText = "";
      this.salesText = "";
      this.paymentsText = "";
      this.summaryText = "";
      searchReport(this.user.branch.id, this.dateReport)
        .then(({ data }) => {
          this.loadingSearch = false;
          this.loading = false;
          this.baseCash = data.header[0];
          this.netSales = data.net_sales;
          this.calculateTotals();
          this.initial_consecutive = data.initial_consecutive
            ? data.initial_consecutive
            : 0;
          this.final_consecutive = data.final_consecutive
            ? data.final_consecutive
            : 0;
          // COMPUTERS
          this.computers = [];
          if (data.computers.length > 0) {
            data.computers.forEach((el) => {
              this.computers.push(el);
              this.computerText +=
                "<div>" +
                JSON.parse(el.computer).name +
                " - " +
                JSON.parse(el.computer).code +
                "</div>";
              this.computerSalesText +=
                "<tr>" +
                '<td style="text-align:center;">' +
                JSON.parse(el.computer).name +
                "</td>" +
                '<td style="text-align:center;">' +
                JSON.parse(el.computer).code +
                "</td>" +
                '<td style="text-align:right;">' +
                el.invoices +
                "</td>" +
                '<td style="text-align:right;">$ ' +
                formatNumber(roundToTheNearestAnything(el.sale)) +
                "</td>" +
                "</tr>";
            });
          }
          // SALES
          if (data.net_sales.length > 0) {
            var salesTotal = 0;
            var ivaTotal = 0;
            var ipcTotal = 0;
            var discountTotal = 0;
            data.net_sales.forEach(function (el, indx, array) {
              salesTotal += parseFloat(el.sale);
              ivaTotal += parseFloat(el.iva);
              ipcTotal += parseFloat(el.ipc);
              discountTotal += parseFloat(el.discount);
              me.salesText +=
                "<tr>" +
                '<td style="text-align:center;">' +
                (indx + 1) +
                "</td>" +
                '<td style="text-align:center;">#' +
                el.invoice +
                "</td>" +
                '<td style="text-align:right;">$ ' +
                formatNumber(el.sale) +
                "</td>" +
                '<td style="text-align:right;">$ ' +
                formatNumber(el.iva) +
                "</td>" +
                '<td style="text-align:right;">$ ' +
                formatNumber(roundToTheNearestAnything(el.ipc)) +
                "</td>" +
                '<td style="text-align:right;">$ ' +
                formatNumber(el.discount) +
                "</td>" +
                "</tr>";
              // set summary
              me.summary[3].sale = salesTotal;
              me.summary[3].iva = ivaTotal;
              me.summary[3].ipc = ipcTotal;
            });
            me.salesText +=
              "<tr>" +
              '<td style="text-align:center;font-weight:bold;border-top: 1px dashed;"></td>' +
              '<td style="text-align:center;font-weight:bold;border-top: 1px dashed;">Total</td>' +
              '<td style="text-align:right;font-weight:bold;border-top: 1px dashed;">$ ' +
              formatNumber(salesTotal) +
              "</td>" +
              '<td style="text-align:right;font-weight:bold;border-top: 1px dashed;">$ ' +
              formatNumber(ivaTotal) +
              "</td>" +
              '<td style="text-align:right;font-weight:bold;border-top: 1px dashed;">$ ' +
              formatNumber(roundToTheNearestAnything(ipcTotal)) +
              "</td>" +
              '<td style="text-align:right;font-weight:bold;border-top: 1px dashed;">$ ' +
              formatNumber(discountTotal) +
              "</td>" +
              "</tr>";
          }
          // PAYMENT
          this.paymentsTotal(data.documents).then((data) => {
            this.payments = data;
            data.forEach(function (el, indx, array) {
              me.paymentsText +=
                "<tr>" +
                '<td style="text-align:center;">' +
                (indx + 1) +
                "</td>" +
                '<td style="text-align:right;">' +
                el.name +
                "</td>" +
                '<td style="text-align:right;">' +
                el.transactions +
                "</td>" +
                '<td style="text-align:right;">$ ' +
                formatNumber(el.value - el.refund) +
                "</td>" +
                "</tr>";
            });
          });

          // SUMMARY
          this.summary.forEach(function (el, indx, array) {
            me.summaryText +=
              "<tr>" +
              '<td><span style="">' +
              el.title +
              "</span></td>" +
              '<td style="text-align:right;font-weight: 600;">$ ' +
              formatNumber(el.sale) +
              "</td>" +
              '<td style="text-align:right;font-weight: 600;">$ ' +
              formatNumber(el.iva) +
              "</td>" +
              '<td style="text-align:right;font-weight: 600;">$ ' +
              formatNumber(roundToTheNearestAnything(el.ipc)) +
              "</td>" +
              "</tr>";
          });
        })
        .catch(function (error) {
          console.log(error);
          this.loadingSearch = false;
          this.loading = false;
        });
    },
    async paymentsTotal(documents) {
      const payments = [];
      this.totalDocument = 0;
      await documents.forEach((el) => {
        if (el.payment_detail) {
          el.payment_detail.forEach((pay) => {
            var pm = payments.find(
              (payment) => payment.name === pay.payment.descripcion
            );
            if (typeof pm === "undefined") {
              payments.push({
                name: pay.payment.descripcion,
                value: parseFloat(pay.valor),
                refund: parseFloat(pay.devolucion),
                transactions: 1,
              });
            } else {
              const index = payments.findIndex(
                (payment) => payment.name === pay.payment.descripcion
              );
              payments[index].value += parseFloat(pay.valor);
              payments[index].refund += parseFloat(pay.devolucion);
              payments[index].transactions += 1;
            }
            this.totalDocument +=
              parseFloat(pay.valor) - parseFloat(pay.devolucion);
          });
        }
      });
      return payments;
    },
    calculateTotals() {
      this.totalSales = 0;
      this.totalIva = 0;
      this.totalIpc = 0;
      this.totalDiscount = 0;
      if (this.netSales.length > 0) {
        this.netSales.forEach((el) => {
          this.totalSales += el.sale;
          this.totalIva += el.iva;
          this.totalIpc += el.ipc;
          this.totalDiscount += el.discount;
        });
      }
    },
    saveReport() {
      //
    },
    getPrincipalStore() {
      getById(1)
        .then(({ data }) => {
          this.principalStore = data;
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    getBaseCashByBranch() {
      getBaseCash(this.user.branch.id, this.dateReport)
        .then(({ data }) => {
          this.baseCash = data[0];
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);
        });
    },
    // print
    print() {
      this.sendPrinter(this.printer_default);
    },
    sendPrinter(printer) {
      let me = this;
      var config = qz.configs.create(printer, { scaleContent: false }); // Create a default config for the found printer
      var background = '';
      var documentHead = '<table style="width:100%">' +
            "<tr>" +
            "<td>" +
            '<div style="font-weight:bold;font-size:18px;">' +
            this.principalStore.razon_social +
            "</div>" +
            "<div> NIT: " +
            this.principalStore.nit +
            "</div>" +
            "</td>" +
            '<td style="text-align:end;font-weight:bold;font-size:18px;">' +
            "<div>Comprobante # " +
            this.baseCash.cuadre +
            "</div>" +
            "<div style='font-size:12px'>Fecha: " +
            this.date +
            "</div>" +
            "<div style='font-size:12px'>Tienda: " +
            this.user.branch.razon_social +
            "</div>" +
            "</td>" +
            "</tr>" +
            "</table>";
      if(this.demo){
        background = 'background-image: url(\''+process.env.VUE_APP_ROOT+'static/images/demo.png\');';
        documentHead =
          '<div style="font-size:30px;text-align:center;">DEMO</div>';
      }
      var printData = [
        {
          type: "pixel",
          format: "html",
          flavor: "plain",
          data:
            '<html>' +
            '<body style="font-size:11px;width:95%;margin-left:20px;'+background+'">' +
            //
            documentHead +
            '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
            // row 2
            '<table style="width:100%">' +
            "<tr>" +
            "<td>" +
            '<div style="font-weight:bold;">Computadores:</div>' +
            this.computerText +
            "</td>" +
            '<td style="text-align:end">' +
            '<div style="font-weight:bold;">Transacciones:</div>' +
            "<div>Desde: " +
            this.initial_consecutive +
            " Hasta: " +
            this.final_consecutive +
            "</div>" +
            "</td>" +
            "</tr>" +
            "</table>" +
            '<div style="border-bottom: solid 1px;margin: 10px 0 5px 0;"></div>' +
            // row 3
            '<table style="width:100%">' +
            "<tr>" +
            '<th style="border-bottom: 1px dashed;">Item</th>' +
            '<th style="border-bottom: 1px dashed;">Factura</th>' +
            '<th style="text-align:right;border-bottom: 1px dashed;">Venta</th>' +
            '<th style="text-align:right;border-bottom: 1px dashed;">IVA</th>' +
            '<th style="text-align:right;border-bottom: 1px dashed;">Ipc</th>' +
            '<th style="text-align:right;border-bottom: 1px dashed;">Descuento</th>' +
            "</tr>" +
            this.salesText +
            "</table>" +
            // summary sales
            '<table style="width:100%;margin-top: 30px;">' +
            "<tr>" +
            '<th style="border-bottom: 1px dashed;">&nbsp;</th>' +
            '<th style="text-align:right;border-bottom: 1px dashed;">Ventas</th>' +
            '<th style="text-align:right;border-bottom: 1px dashed;">IVA</th>' +
            '<th style="text-align:right;border-bottom: 1px dashed;">Impuesto al consumo bolsas</th>' +
            "</tr>" +
            this.summaryText +
            "</table>" +
            // row 4
            '<div style="margin-top:30px;margin-bottom:15px;font-weight:bold;font-size:18px;">Ventas por dispositivo</div>' +
            '<table style="width:100%">' +
            "<tr>" +
            '<th style="border-bottom: 1px dashed;">Nombre</th>' +
            '<th style="border-bottom: 1px dashed;">Código</th>' +
            '<th style="text-align:right;border-bottom: 1px dashed;">Transacciones</th>' +
            '<th style="text-align:right;border-bottom: 1px dashed;">Total venta</th>' +
            "</tr>" +
            this.computerSalesText +
            "</table>" +
            // row 5
            '<div style="margin-top:30px;margin-bottom:15px;font-weight:bold;font-size:18px;">Medios de pago</div>' +
            '<table style="width:100%">' +
            "<tr>" +
            '<th style="border-bottom: 1px dashed;">Item</th>' +
            '<th style="text-align:right;border-bottom: 1px dashed;">Nombre</th>' +
            '<th style="text-align:right;border-bottom: 1px dashed;">Transacciones</th>' +
            '<th style="text-align:right;border-bottom: 1px dashed;">Total venta</th>' +
            "</tr>" +
            this.paymentsText +
            "</table>" +
            '<div style="border-bottom: solid 1px;margin: 10px 0 5px 0;"></div>' +
            '<div style="margin-top:30px;font-size:20px;font-weight:bold;">TOTAL <label style="float:right;">$ ' +
            formatNumber(this.totalDocument) +
            "</label></div>" +
            "</body>" +
            "</html>",
        },
      ];
      qz.print(config, printData)
        .then(function () {
          console.log("Print finish!");
          // me.$store.commit("SET_ID_DOCUMENT", "");
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    // finish print
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(number);
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
    roundNumber(number) {
      return roundToTheNearestAnything(number);
    },
    startConnectionPrint() {
      let me = this;
      setTimeout(() => {
        // Authentication setup ///
        var privateKey =
          "-----BEGIN PRIVATE KEY-----\n" +
          "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCn13RTAjw4IfTw\n" +
          "d46mk3Lw7YLFJbIGdZvV4ieg51+fqqyUk81+q0icBIAnsrg3j2J+oBlwd1HLgaeI\n" +
          "C+8tEVn3ed9tzvxhJ5iGmd9DUorPIP2RsX+62uKi7OV4fXYBkqSXKWdaoI0nLEwf\n" +
          "FkCL7b8UN3LUazECIsK4bXeYIFMgAERYr6lvtP8HTJ3t6d/W0BWNQgXpUMuGJhvo\n" +
          "5yiJhsVZp8YjF/xyBcQRZjx58Ze4n426dM4lT5cH7EDJk5iogL/9D5YmPZ8dFMVZ\n" +
          "yaAfVVvT1x3vC7GqboKbYISNZLyVM3/+F3ikkcGe/NLbKUwdnFc+rKOdGp/Dup8T\n" +
          "HWVpm9MfAgMBAAECggEAGV2Z8mudjzwuiOMAt5MEJX43q7TZNVmz3fyqUYp2LKq+\n" +
          "H0xBG7omJJIXjihX5+mvEIUAozsdLPzgxVJMaMq9BoYwKqfEA6jYx8rcb4+7c7dA\n" +
          "KzMgoywuYUIdIfsQCQfRARlcSdmGy5tJu88AnUUmghcpLwouI2z+caH1l5OXDHhP\n" +
          "LbN+opDi0EhCd+PoNInkpj9s12/tl8rOOlHJSGUSgIAZGyafS5llBm3JoO4dZUmQ\n" +
          "0Lq1tKI25ai7B7EtZ+3Dyvya6N+5ACXucwlsufROc4kLrGbAffEPkreHydD78iSf\n" +
          "d1VMUuDABnUawPOLnd3yKBwAfvxN6jhjlYThw7rNRQKBgQDVX17U7H+IZsbvbknT\n" +
          "XsJQJlE+fdUYI6EImBDs9MG34akpTi+MdgdKJOKTJIVQIm5pxOD1nxzrY1vlNRML\n" +
          "hA8o0ZACcsION/476puRfG2LhxVak+gN+P0NlWXY4R1QsLSsc/KaL1MoBO2lom7+\n" +
          "C9l+KIG544ZzfK/hirVhPKQQZQKBgQDJX3iMS37VLu+2OQ26L9xstH33C9R73DJJ\n" +
          "mpPXra1KA1hgPYETy7769LMdYV7vKsyh4++4VgWAmSLkOK0H/ViQpmcW+kyyLQ9w\n" +
          "euD87zRcjIIY0ehI8JyLHX9Dxcuy4/oJDn+0IRXXkHDfSrw6kfkFuP+khTSRbbAj\n" +
          "q0L7vyfjMwKBgCwC5f6pYYzzFimg6B1vOElr/eoT7nZyMu8g3qzht2uEYm4gsHIa\n" +
          "jlpezVVofQ8QaxHqCDThwwdSkUvv7KO9c5sJztiSbzCboBcfx0L1FWtoS6FJTN5E\n" +
          "831vyXg4xuEz8bB4ohvnkxF4IsS9goNoUk5RcKC1K7B6kZTGFa+e4IB5AoGAccGK\n" +
          "+cYUKh3PsKLeTFWJMadsmUiHikjD56x45Bfqkqr9G7Di3+nzQxVEsc41sOn+m12G\n" +
          "KB4AEbtwaHhT1vWZOG2oQuS2utiVpjbkLj561+VQAPFjRQEXjcbmA8US40XCE6er\n" +
          "IHioPBQOjzVKy9N2V3NAhxHwtk6+eAfhWRGNWN0CgYA6XAiF1Al+kYkosSKsjpxo\n" +
          "BdKzT+5vCw37Iq3F+DYxzRb8MKx9XpeGoEWbG62Xez7eZ2B6UaARGDu43Ioa/6vk\n" +
          "bh9647T6nehoGlagzdu92kO5FCFsoIB2pBBZAS5k7S8jtSS7To2W3FDY/o3wwIRg\n" +
          "spuV+871lajMrXi3/faa/w==\n" +
          "-----END PRIVATE KEY-----";

        qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
        qz.security.setSignaturePromise(function (toSign) {
          return function (resolve, reject) {
            try {
              var pk = KEYUTIL.getKey(privateKey);
              var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
              sig.init(pk);
              sig.updateString(toSign);
              var hex = sig.sign();
              // console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
              resolve(stob64(hextorstr(hex)));
            } catch (err) {
              console.error(err);
              reject(err);
            }
          };
        });
        qz.security.setCertificatePromise(function (resolve, reject) {
          resolve(axios.get(process.env.VUE_APP_ROOT_API + '/certs/getQzCertificate').then(response => response.data))
        });
        if (!qz.websocket.isActive()) {
          qz.websocket.connect().then(function () {
            console.log("Connected QZ-tray! ", qz.websocket.isActive());
            me.$store.commit("SET_PRINTER_CONNECT", true);
            me.$store.commit("SET_PRINTER_RECONNECT", false);
            qz.printers
              .getDefault()
              .then(function (data) {
                me.$store.commit("SET_PRINTER", data);
              })
              .catch(function (error) {
                console.log("error: ", error);
              });
          });
        } else {
          me.$message({
            message: "La conección con la impresora, ya ha sido establecida.",
            type: "success",
          });
          me.$store.commit("SET_PRINTER_RECONNECT", false);
        }
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
.el-date-editor {
  width: 200px;
}
.date-report {
  margin-right: 10px;
}
</style>
<style lang="scss" scoped>
@import "../../../assets/scss/_variables";
.total_document {
  .text,
  .value {
    font-size: 20px;
    font-weight: bold;
    text-align: end;
  }
}
.summary {
  margin-top: 30px;
  .summary_values {
    font-weight: 600;
  }
}
.page-invoice {
  .toolbar {
    max-width: 794px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .invoice {
    background: white;
    width: 100%;
    max-width: 794px;
    min-height: 1123px;
    margin: 0 auto;
    padding: 2em;
    box-sizing: border-box;
    box-shadow: 0px 8px 8px -8px rgba(0, 0, 0, 0.3);

    .watermark {
      position: fixed;
      bottom: 5px;
      right: 5px;
      opacity: 0.5;
      z-index: 99;
      color: rgb(236, 16, 16);
    }

    hr {
      border: 1px solid rgba(0, 0, 0, 0.05);
    }

    .invoice-top {
      overflow: hidden;
      margin-bottom: 30px;

      .logo {
        float: left;
        height: 60px;
        width: 60px;
        box-sizing: border-box;
        border-radius: 50%;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.07);
        text-align: center;

        img {
          height: 80%;
          margin-top: 10%;
        }
      }
      .info {
        display: block;
        float: left;
        margin-left: 20px;
        margin-top: 5px;

        & > * {
          margin: 0;
        }
      }
      .title {
        float: right;

        & > * {
          margin: 0;
        }

        p {
          text-align: right;
        }
      }
    }

    .invoice-middle {
      overflow: hidden;
      margin-top: 30px;
      margin-bottom: 30px;

      .clientlogo {
        float: left;
        height: 60px;
        width: 60px;
        box-sizing: border-box;
        border-radius: 50%;
        overflow: hidden;
        background: grey;

        img {
          width: 100%;
        }
      }

      .info {
        display: block;
        float: left;
        margin-left: 20px;
        margin-top: 5px;

        & > * {
          margin: 0;
        }
      }

      .project {
        margin-left: 52%;

        & > * {
          margin: 0;
        }
      }
    }

    .invoice-bottom2 {
      table {
        width: 100%;
        border-collapse: collapse;

        th {
          text-align: right;
          // padding: 20px;
          padding-bottom: 5px;
          opacity: 0.7;
        }

        td {
          text-align: right;
          // padding: 20px;
          padding-bottom: 5px;
          border-bottom: 1px solid #eee;
        }

        tfoot td {
          border: none;
          padding-top: 5px;
        }
      }
      .totals,
      .total {
        font-weight: bold;
      }
    }

    h1 {
      font-size: 1.5em;
      color: #222;
    }
    h2 {
      font-size: 0.9em;
    }
    h3 {
      font-size: 1.2em;
      font-weight: 300;
      line-height: 2em;
    }
    p {
      font-size: 0.7em;
      color: #666;
      line-height: 1.2em;
    }
  }
}

@media screen and (max-width: 768px) {
  .page-invoice {
    .invoice {
      .invoice-middle {
        .project {
          display: block;
          width: 100%;
          clear: both;
          padding: 10px 0;
          margin: 0;
        }
      }
      .invoice-bottom {
        table {
          th {
            padding: 5px;
          }

          td {
            padding: 5px;
          }
        }
      }
    }
  }
}

@media print {
  .layout-container .container .footer,
  .layout-container .container .header,
  .layout-container .container .horizontal-nav,
  .layout-container .footer,
  .layout-container .layout-picker,
  .layout-container .vertical-nav,
  .layout-container .header {
    display: none;
  }

  .page-invoice {
    .toolbar {
      display: none;
    }

    .invoice {
      box-shadow: none;
      max-width: 100%;
      padding: 0;
    }
  }
}
</style>

