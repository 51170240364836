import request from '@/utils/request'

export function store(data) {
  return request({
    url: 'store/store',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: 'store/update',
    method: 'put',
    data
  })
}

export function getAll() {
  return request({
    url: 'store/stores',
    method: 'get'
  })
}

export function getById(id) {
  return request({
    url: 'store/getById/' + id,
    method: 'get'
  });
}

export function deleteData(id) {
  return request({
    url: 'store/destroy/' + id,
    method: 'delete'
  });
}

export function getComputers(branch_id) {
  return request({
    url: 'store/getComputers/' + branch_id,
    method: 'get',
  });
}

export function saveComputers(data) {
  return request({
    url: 'store/saveComputers',
    method: 'post',
    data
  });
}

export function getBaseCash(branch_id, date) {
  return request({
    url: 'store/getBaseCash/' + branch_id + '/' + date,
    method: 'get'
  });
}

export function getUpfReport(zoneId, branchId, date) {
  return request({
    url: 'store/getUpfReport/' + zoneId + '/' + branchId + '/' + date,
    method: 'get',
  });
}

export function downloadThirds(data) {
  return request({
    url: 'store/downloadThirds',
    method: 'post',
    data
  });
}