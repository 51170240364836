<template>
  <el-dialog
    :visible.sync="modalOpen"
    :before-close="modalClose"
    :destroy-on-close="true"
    width="30%"
  >
    <div slot="title">
      <i class="el-icon-download"></i>
      Descargar Terceros
    </div>
    <el-row :gutter="20" style="margin-top: 10px">
      <el-col :span="24">
        <span>Selecciona un rango de fechas</span>
        <el-date-picker
          v-model="date"
          type="daterange"
          start-placeholder="Fecha inicial"
          end-placeholder="Fecha final"
          format="yyyy/MM/dd"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 10px">
      <el-col :span="24">
        <span>Selecciona una tienda</span>
        <el-select
          v-model="branch"
          filterable
          value-key="id"
          placeholder="Seleccione la tienda"
          clearable
        >
          <el-option
            v-for="item in stores"
            :key="item.id"
            :label="item.razon_social"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <download-excel
        style="float: right; margin-left: 10px"
        :data="json_data"
        :fields="json_fields"
        :title="[]"
        :name="nameDownload"
        :before-generate="startDownload"
        :before-finish="finishDownload"
        v-show="!loadingDownload"
      >
        <el-button size="medium" type="warning" icon="el-icon-download"
          >Descargar</el-button
        >
      </download-excel>
      <el-button
        size="medium"
        type="warning"
        icon="el-icon-download"
        style="float: right; margin-left: 10px"
        :loading="true"
        v-show="loadingDownload"
        >Descargando..</el-button
      >
      <el-button @click="modalClose" icon="el-icon-close" size="medium"
        >Cancelar</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getAll as getStores, downloadThirds } from "@/api/store";
export default {
  name: "ModalThirds",
  props: ["modalOpen"],
  watch: {},
  data() {
    return {
      loadingDownload: false,
      stores: [],
      date: "",
      branch: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "-Última semana",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "-Último mes",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "-Últimos 3 meses",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      //
      json_data: [],
      json_fields: {
        Tienda: "store",
        Documento: "document",
        Cliente: "client",
        Telefono: "phone",
        Email: "email",
        Ciudad: "city",
        Direccion: "address",
      },
      nameDownload: "",
    };
  },
  methods: {
    async startDownload() {
      let me = this;
      if (
        this.date !== "" &&
        this.date !== null &&
        this.branch !== null &&
        this.branch !== ""
      ) {
        this.loadingDownload = true;
        await this.downloadReport();
        this.nameDownload =
          "Clientes " +
          this.branch.razon_social +
          " " +
          this.date[0] +
          "-" +
          this.date[1];
      }else{
        this.$message.warning("Atención! Selecciona las opciones por favor");
      }
    },
    finishDownload() {
      // this.json_data = [];
    },
    async downloadReport() {
      await downloadThirds({
        date: this.date,
        store_id: this.branch.id,
      })
        .then(({ data }) => {
          this.setJsonData(data.data);
        })
        .catch(function (error) {
          this.loadingDownload = false;
          console.log(error);
        });
    },
    async setJsonData(data) {
      let me = this;
      me.json_data = [];
      data.forEach(async (sell) => {
        await me.json_data.push({
          store: this.branch.razon_social,
          document: sell.documento,
          client: sell.nombre,
          phone: sell.telefono,
          email: sell.email,
          city: sell.ciudad,
          address: sell.direccion,
        });
      });
      this.loadingDownload = false;
    },
    modalClose() {
      this.$emit("close");
    },
    getStores() {
      getStores()
        .then(({ data }) => {
          this.stores = data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getStores();
  },
};
</script>