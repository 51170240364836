//pages
import Index from '@/views/apps/Document/Index.vue'
import InputSetting from '@/views/apps/Document/InputSetting/Index.vue'
import LoadInventory from '@/views/apps/Document/LoadInventory/Index.vue'
import OutputSetting from '@/views/apps/Document/OutputSetting/Index.vue'
import layouts from '@/layout'

export default {
  path: 'inputSetting',
  name: 'Ajustes y Cargue',
  component: Index,
  redirect: { path: '/inputSetting', name: 'inputSetting' },
  icon: 'file-move',
  auth: true,
  meta: {
		nav: true,
		roles: ['admin', 'management', 'employed']
	},
  children: [
    {
      path: '/inputSetting',
      name: 'Ajuste de entrada',
      component: InputSetting,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Ajuste de entrada'],
        roles: ['admin', 'management', 'employed'],
        icon: 'file-upload-outline'
      }
    },
    {
      path: '/outputSetting',
      alias: '/outputSetting',
      name: 'Ajuste de salida',
      component: OutputSetting,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Ajuste de salida'],
        roles: ['admin'],
        icon: 'file-download-outline'
      }
    },
    {
      path: '/load',
      alias: '/load',
      name: 'Cargue inventario',
      component: LoadInventory,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Cargue de inventario'],
        roles: ['admin'],
        icon: 'file-replace-outline'
      }
    },
  ]
}