//pages
import Index from '@/views/apps/administration/Index.vue'
import Employed from '@/views/apps/administration/Employed.vue'
import UnitMeasurement from '@/views/apps/administration/UnitMeasurement.vue'
import Thirds from '@/views/apps/administration/thirds/Index.vue'
import layouts from '@/layout'

export default {
  path: 'employed',
  name: 'Administración',
  component: Index,
  redirect: { path: '/employed', name: 'employed' },
  icon: 'account-tie',
  auth: true,
  meta: {
		nav: true,
		roles: ['admin', 'management']
	},
  children: [
    {
      path: '/employed',
      name: 'Empleados',
      component: Employed,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Empleados'],
        roles: ['admin', 'management'],
        icon: 'account-group-outline'
      }
    },
    {
      path: '/thirds',
      name: 'Terceros',
      component: Thirds,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Terceros'],
        roles: ['admin', 'management'],
        icon: 'account-tie'
      }
    },
    {
      path: '/unitMeasurement',
      alias: '/unitMeasurement',
      name: 'Unidad de medida',
      component: UnitMeasurement,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Unidad de medida'],
        roles: ['admin'],
        icon: 'weight-kilogram'
      }
    }
  ]
}