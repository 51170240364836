import { getUser } from "@/utils/auth";

export default {
  validateRoles(el, binding){
    const roles = getUser().roles;
    const value = binding.value;
    var show = false;
    if (Array.isArray(value)) {
      for (let i = 0; i < roles.length; i++) {
        if (value.includes(roles[i].guard_name)) {
          show = true
        }
      }
    } else {
      if (value !== "" && typeof value !== 'undefined') {
        const found = roles.find(element => element.guard_name === value);
        show = (typeof found !== 'undefined') ? true : false
      } else {
        show = true
      }
    }
    el.style.display = (show) ? 'inline-block' : 'none'
  },
  inserted: function (el, binding) {
    binding.def.validateRoles(el, binding);
  },
  componentUpdated: function (el, binding) {
    binding.def.validateRoles(el, binding);
  },
}