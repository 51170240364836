import { getConfig } from "@/utils/auth";

export default {
  validateConfig(el, binding){
    const config = getConfig();
    const value = binding.value;
    var show = true;
    if (Array.isArray(value)) {
      const dataConfig = JSON.parse(config);
      for (let i = 0; i < dataConfig.length; i++) {
        if (value.includes(dataConfig[i].slug)) {
          show = true
        }
      }
    } else {
      if (value !== "" && typeof value !== 'undefined') {
        const dataConfig = JSON.parse(config);
        const found = dataConfig.find(element => element.slug === value);
        if (typeof found !== 'undefined') {
          if (found.slug === 'paused_bills') {
            if (parseInt(found.value)) {
              show = true;
            }else{
              show = false;
            }
          }else{
            show = true;
          }
        }else{
          showe = false;
        }
        // show = (typeof found !== 'undefined') ? true : false
      } else {
        show = true
      }
    }
    el.style.display = (show) ? 'inline-block' : 'none'
  },
  inserted: function (el, binding) {
    binding.def.validateConfig(el, binding);
  },
  componentUpdated: function (el, binding) {
    binding.def.validateConfig(el, binding);
  },
}