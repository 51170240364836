<template>
  <div class="step3">
    <div class="content">
      <el-row :gutter="12">
        <el-col :span="24">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span
                ><strong>{{ refund_type.descripcion }}</strong></span
              >
              <el-divider direction="vertical"></el-divider>
              <span>Factura # {{ refund_data.invoice }}</span>
              <el-divider direction="vertical"></el-divider>
              <span
                >Cliente: {{ refund_data.document.person.nombre }}</span
              >
              <el-divider direction="vertical"></el-divider>
              <span
                >Nit o CC:
                {{ refund_data.document.person.documento }}</span
              >
            </div>
            <div class="content_card">
              <div class="observation">
                <span class="title">Observación:</span>
                {{ refund_data.observation }}
              </div>
              <el-table :data="refund_data.detail" height="200" size="small">
                <el-table-column prop="code" label="Código" width="150">
                </el-table-column>
                <el-table-column prop="description" label="Descripción">
                </el-table-column>
                <el-table-column prop="quantity" label="Cantidad" width="80">
                </el-table-column>
                <el-table-column prop="price" label="Precio" width="100">
                  <template slot-scope="scope">
                    $ {{ formatNumber(scope.row.price) }}
                  </template>
                </el-table-column>
                <el-table-column prop="iva" label="Iva" width="90">
                  <template slot-scope="scope">
                    $ {{ formatNumber(scope.row.iva) }}
                  </template>
                </el-table-column>
                <el-table-column prop="total" label="Total" width="100">
                  <template slot-scope="scope">
                    $ {{ formatNumber(scope.row.total) }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { formatNumber } from "@/utils/number";
export default {
  name: "step3",
  computed: {
    ...mapGetters(["refund_type", "refund_data"]),
  },
  data() {
    return {
      //
    };
  },
  methods: {
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(number);
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 50%;
}
.box-card {
  height: 320px;
}
.observation {
  padding-left: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  .title {
    font-weight: bold;
  }
}
</style>