//pages
import Index from '@/views/apps/reports/Index.vue'
import Inventory from '@/views/apps/reports/Inventory/Index.vue'
import Kardex from '@/views/apps/reports/Kardex.vue'
import DailyInventoryEmployed from '@/views/apps/reports/DailyInventoryEmployed/Index.vue'
import layouts from '@/layout'

export default {
  path: 'inventory',
  name: 'Informes',
  component: Index,
  redirect: { path: '/inventory', name: 'inventory' },
  icon: 'file-document-outline',
  auth: true,
  meta: {
		nav: true,
		roles: ['admin','management', 'cashier']
	},
  children: [
    {
      path: '/inventory',
      alias: '/inventory',
      name: 'Inventario',
      component: Inventory,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Inventario'],
        roles: ['admin', 'management','cashier'],
        icon: 'file-table-outline'
      }
    },
    {
      path: '/dailyInventoryEmployed',
      alias: '/dailyInventoryEmployed',
      name: 'Reporte Empleado',
      component: DailyInventoryEmployed,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Inventario'],
        roles: ['admin', 'management'],
        icon: 'account-clock-outline'
      }
    },
    {
			path: '/kardex',
			name: 'Kardex',
			component: Kardex,
			meta: {
				nav: true,
				layout: layouts.navLeft,
				roles: ['admin', 'deposit'],
				icon: 'cash-register',
			}
		}
  ]
}