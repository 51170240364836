<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <modal-list-document :typeDocument="form.type_id" :transaction="2"></modal-list-document>
          
          <el-form :model="form" :rules="rules" ref="form" status-icon label-width="70px" size="medium" class="form"
            :inline="true" :label-position="'top'">
            <el-form-item label="Cantidad" prop="quantity">
              <el-input-number v-model="form.quantity" :min="1" :max="100000"></el-input-number>
            </el-form-item>
            <el-form-item label="Código / Producto" prop="code">
              <el-select v-model="form.code" filterable remote clearable reserve-keyword
                placeholder="Buscar Producto..." :remote-method="remoteMethod" :loading="loadingProduct"
                @clear="form.code = ''" loading-text="Cargando...">
                <i slot="prefix" class="el-input__icon el-icon-shopping-cart-2"></i>
                <el-option v-for="item in options" :key="item.id" :label="item.descripcion" :value="item.codigo_interno"
                  class="options-product">
                  <div><strong><i class="mdi mdi-cart-outline"></i> {{ item.descripcion }}</strong></div>
                  <span style="color: #8492a6; font-size: 13px">
                    <i class="mdi mdi-barcode-scan"></i> {{item.codigo_interno }}
                    <i class="mdi mdi-tag-outline"></i> {{item.category.descripcion }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <label slot="label">&nbsp;</label>
              <el-button type="primary" @click="submitForm('form')" :loading="loading" icon="el-icon-plus">Agregar
              </el-button>
              <el-button type="success" icon="el-icon-check" :disabled="btnSaveDisabled" @click="saveLoad">Guardar Ajuste de Salida</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <list :get="getData" @complete="complete" @quantity_detail="validateQuantityDetail($event, true)"></list>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import ModalListDocument from '../ModalListDocument.vue';
import { loadInventory, saveLoadInventory } from "@/api/document";
import { searchProductByData } from "@/api/product";
import List from "./List.vue";
export default {
  name: "OutputSetting",
  components: {
    List,
    ModalListDocument,
  },
  data() {
    return {
      loading: false,
      loadingTable: false,
      btnSaveDisabled: true,
      getData: false,
      modalOpen: false,
      loadingProduct: true,
      options: [],
      form: {
        code: "",
        quantity: 1,
        type_id: 8, // ajuste de salida
      },
      rules: {
        code: [
          {
            required: true,
            message: "Ingresa una código",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    remoteMethod(query) {
      if (query !== "" && query.length > 2) {
        this.loadingClient = true;
        searchProductByData({ data: query })
          .then(({ data }) => {
            this.loadingProduct = false;
            this.options = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.options = [];
      }
    },
    validateQuantityDetail(data){
      this.btnSaveDisabled=data
    },
    saveLoad() {
      let me = this;
      this.$msgbox({
        title: "Atención",
        message:
          "Se ajustarán los datos en el inventario de la tienda. Desea continuar?",
        showCancelButton: true,
        confirmButtonText: "Si, ajustar salida",
        cancelButtonText: "No, cancelar",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Cargando datos...";
            saveLoadInventory({ type_id: this.form.type_id })
              .then(({ data }) => {
                if (data.code === 200) {
                  me.getData = true;
                  me.resetForm("form");
                  me.$message({
                    message: "Se ha ajustado el inventario en la tienda",
                    type: "success",
                  });
                } else {
                  me.$message.error("Error: " + data.error);
                }
                me.loadingTable = false;
                done();
                instance.confirmButtonLoading = false;
              })
              .catch(function (error) {
                instance.confirmButtonLoading = false;
                me.loadingTable = false;
                me.$message.error("Error! " + error.response.data.message);
                console.log("Err: ", error);
              });
          } else {
            done();
          }
        },
      });
    },
    submitForm() {
      let me = this;
      me.$refs['form'].validate((valid) => {
        if (valid) {
          me.loading = true;
          loadInventory(me.form)
            .then(({ data }) => {
              if (data.code === 200) {
                me.getData = true;
                me.resetForm("form");
                me.$message({
                  message: "Producto cargado!",
                  type: "success",
                });
              } else {
                me.$message.warning("Error: " + data.data);
              }
              me.loading = false;
              me.loadingTable = false;
            })
            .catch(function (error) {
              me.loading = false;
              me.loadingTable = false;
              me.$message.error("Error! " + error.response.data.message);
              console.log("Err: ", error);
            });
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.loading = false;
      this.loadingTable = false;
    },
    complete(complete) {
      this.getData = false;
    },
  },
};
</script>