import request from '@/utils/request'

export function getComputers(branch_id) {
  return request({
    url: 'store/getComputers/' + branch_id,
    method: 'get',
  });
}

export function registerCash(data) {
  return request({
    url: 'store/registerCash',
    method: 'post',
    data
  });
}

export function getAll() {
  return request({
    url: 'store/stores',
    method: 'get'
  })
}

export function getById(id) {
  return request({
    url: 'store/getById/' + id,
    method: 'get'
  });
}

export function getBaseCash(branch_id, date) {
  return request({
    url: 'store/getBaseCash/' + branch_id + '/' + date,
    method: 'get'
  });
}

export function getIncomingTransfers(data) {
  return request({
    url: 'store/getIncomingTransfers',
    method: 'post',
    data
  });
}

export function getUpfReport(zoneId, branchId, date) {
  return request({
    url: 'store/getUpfReport/' + zoneId + '/' + branchId + '/' + date,
    method: 'get',
  });
}