<template>
  <div>
    <el-dialog
      width="70%"
      height="500"
      top="50px"
      :visible.sync="modalListOpen"
      :before-close="modalListClose"
    >
      <div slot="title">
        <i class="mdi mdi-file-search-outline"></i> Devoluciones
      </div>
      <el-card class="card-shadow--small">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input
              placeholder="Número de documento"
              size="mini"
              v-model="consecutive"
              clearable
              @clear="consecutive = null"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-select
              v-model="type_id"
              size="mini"
              multiple
              clearable
              placeholder="Tipo Documento"
              @clear="type_id = null"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <el-option
                v-for="item in types"
                :key="item.id"
                :label="item.descripcion"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-date-picker
              v-model="date"
              type="date"
              size="mini"
              placeholder="Fecha"
              format="yyyy/MM/dd"
              value-format="yyyy-MM-dd"
              @clear="date = null"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="6">
            <el-select
              v-model="client"
              filterable
              remote
              clearable
              reserve-keyword
              size="mini"
              placeholder="Buscar Cliente"
              :remote-method="remoteMethod"
              :loading="loadingClient"
              @clear="client = null"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.nombre"
                :value="item.id"
              >
                <span style="float: left">{{ item.nombre }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"
                  ><i class="el-icon-postcard"></i> {{ item.documento }}</span
                >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              :loading="loadingFilter"
              @click="filterTable"
              >Buscar</el-button
            >
          </el-col>
        </el-row>
      </el-card>
      <!-- TABLE DATA -->
      <el-table
        :data="tableData"
        v-loading="loading"
        height="400"
        style="width: 100%"
      >
        <el-table-column prop="consecutivo" label="Consecutivo" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.consecutivo }} </span>
            <el-tag v-if="scope.row.estatus == '3'" size="mini" type="danger"
              >Anulado</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="type.descripcion"
          label="Tipo Documento"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="fecha"
          label="Fecha"
          width="150"
        ></el-table-column>
        <el-table-column prop="person.nombre" label="Cliente"></el-table-column>
        <el-table-column
          prop="branch.razon_social"
          label="Sucursal"
        ></el-table-column>
        <el-table-column
          label="Acciones"
          width="100"
          class="align-vertical-middle"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.estatus != '3'">
              <el-tooltip
                class="btn-print"
                effect="dark"
                content="Imprimir"
                placement="top"
              >
                <el-button
                  size="mini"
                  circle
                  @click="printDocument(scope.$index, scope.row)"
                >
                  <i class="el-icon-printer"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="Eliminar"
                placement="top"
              >
                <el-popconfirm
                  confirmButtonText="Si"
                  cancelButtonText="No, Cancelar"
                  icon="el-icon-info"
                  iconColor="red"
                  title="Deseas eliminar este registro?"
                  @confirm="deleteRecord(scope.row)"
                >
                  <el-button
                    slot="reference"
                    size="mini"
                    v-can="'admin'"
                    type="danger"
                    circle
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </el-popconfirm>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="tableData.length > 0"
        class="pagination-box"
        style="text-align: end; padding-top: 10px"
      >
        <el-pagination
          layout="total, prev, pager, next"
          :page-size="pageSize"
          :total="total_rows"
          @current-change="handleCurrentPage"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" icon="el-icon-close" @click="modalListClose"
          >Cerrar</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUser } from "@/utils/auth";
import { getDocuments, anulateDocument } from "@/api/document";
import { getTypesSelected } from "@/api/type_document";
import { search as searchClient } from "@/api/person";
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
} from "@/utils/number";
export default {
  name: "ModalList",
  props: ["modalListOpen"],
  watch: {
    modalListOpen(val) {
      if (val) {
        // this.getData();
      }
    },
  },
  data() {
    return {
      user: getUser(),
      loading: false,
      loadingFilter: false,
      loadingClient: false,
      table: null,
      tableData: [],
      types: [],
      options: [],
      // Filters
      consecutive: null,
      type_id: null,
      date: null,
      client: null,
      // Pagination
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
    };
  },
  methods: {
    printDocument(idx, row) {
      this.$emit("print", row.id);
    },
    modalListClose() {
      this.$emit("close");
    },
    filterTable() {
      this.loadingFilter = true;
      this.getData();
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getData();
    },
    getData() {
      this.type_id =
        this.type_id != null
          ? this.type_id.length > 0
            ? this.type_id
            : null
          : null;
      this.loading = true;
      if (
        this.consecutive != null ||
        this.date != null ||
        this.client != null ||
        this.type_id != null
      ) {
        this.currentPage = 1;
      }
      getDocuments(
        this.pageSize,
        this.currentPage,
        this.type_id, // types document filter 5,31
        this.consecutive,
        this.date,
        this.client
      )
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loading = false;
          this.loadingFilter = false;
        })
        .catch(function (error) {
          this.loadingFilter = false;
          this.loading = false;
          console.log(error);
        });
    },
    getTypes() {
      getTypesSelected({ data: [5, 10] })
        .then(({ data }) => {
          this.types = data;
        })
        .catch(function (error) {
          console.log(error);
          this.$message.error("Oops", "Error");
        });
    },
    deleteRecord(row) {
      this.$prompt("Ingres el concepto de la anulación", "Concepto", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        inputValidator: (val) => {
          return val ? true : false;
        },
        inputErrorMessage: "Ingrese un concepto de anulación",
      })
        .then(({ value }) => {
          this.anulateDocument(row.id, value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Anulación Cancelada",
          });
        });
    },
    anulateDocument(id, concept) {
      this.loading = true;
      anulateDocument({ id_document: id, concept: concept })
        .then(({ data }) => {
          console.log("resp", data);
          this.getData()
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loadingClient = true;
        searchClient(query, "cliente")
          .then(({ data }) => {
            this.loadingClient = false;
            this.options = data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.options = [];
      }
    },
  },
  mounted() {
    this.getTypes();
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.btn-print {
  margin-right: 10px;
}
</style>