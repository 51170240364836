<template>
  <el-dialog title="Productos simples que no tienen inventario" width="50%" :visible.sync="openModalWithoutProducts"
    :before-close="closeModal" :append-to-body="true" :destroy-on-close="true">
    <el-alert title="Atención" type="warning" :description="message" :closable="false" show-icon>
    </el-alert>
    <div>&nbsp;</div>
    <el-table :data="dataWithoutProducts.withoutBalance" style="width: 100%">
      <el-table-column prop="code" label="Código">
      </el-table-column>
      <el-table-column prop="descripcion" label="Producto simple">
      </el-table-column>
      <el-table-column prop="balance_necessary" label="Saldo necesario">
      </el-table-column>
      <el-table-column prop="balance" label="Saldo Inventario">
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal()" icon="el-icon-close" size="small">Cerrar</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: ["openModalWithoutProducts", "dataWithoutProducts"],
  watch: {
    dataWithoutProducts: {
      deep: true,
      handler(val, oldVal) {
        if (Object.keys(val).length !== 0) {
          this.message = 'Estos productos simples de "' + ((val.product) ? val.product.descripcion : null) + '" no tienen inventario en el sistema';
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      message: ''
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">

</style>