<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form :model="form" ref="form" :label-position="'top'" status-icon label-width="70px" size="medium" class="form" :inline="true">

            <el-form-item label="Empleado" prop="employed_id">
              <el-select v-model="form.employed_id" filterable placeholder="Selecciona una empleado"
                @clear="form.employed_id = ''" clearable>
                <el-option v-for="item in employeds" :key="item.id" :label="item.complete_name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Rango de fechas" prop="dates">
              <el-date-picker v-model="form.dates" type="daterange" start-placeholder="Fecha de inicio"
                end-placeholder="Fecha de fin" format="yyyy/MM/dd" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <label slot="label">&nbsp;</label>
              <el-button type="primary" @click="submitForm('form')" :loading="loading" icon="el-icon-search">Filtrar
              </el-button>
              <el-button type="warning" @click="downloadInventory" :loading="loadingDownload"
                :disabled="!showBtnDownload" icon="el-icon-download">Descargar Excel</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <list :get="getData" :form="form" @complete="complete" @table-data="tableData"></list>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { dowloadDailyInventoryEmployed } from "@/api/document";
import { getAll as getEmployed } from "@/api/administration/employed";
import List from "./List.vue";
import { actualDate } from "@/utils/date";
export default {
  name: "Inventory",
  components: {
    List,
  },
  data() {
    return {
      loading: false,
      loadingTable: false,
      loadingDownload: false,
      getData: false,
      modalOpen: false,
      showBtnDownload: false,
      form: {
        employed: "",
        employed_id: "",
        dates: "",
      },
      employeds: [],
    };
  },
  methods: {
    downloadInventory() {
      let me = this;
      me.loadingDownload = true;
      dowloadDailyInventoryEmployed({
        employed_id: this.form.employed_id,
        dates: this.form.dates,
      })
        .then(({ data }) => {
          var dateString = actualDate().substring(0, 10);
          const url = URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Reporte de inventario empleado " + dateString);
          document.body.appendChild(link);
          link.click();
          me.loadingDownload = false;
        })
        .catch(function (error) {
          me.loadingDownload = false;
          console.log(error);
          this.$message({
            type: "error",
            message: "Error! ",
          });
        });
    },
    submitForm() {
      this.getData = true;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.loading = false;
      this.loadingTable = false;
    },
    complete(complete) {
      this.getData = false;
    },
    tableData(data) {
      console.log(data);
      if (data.length > 0) {
        this.showBtnDownload = true
      } else {
        this.showBtnDownload = false;
      }
    },
    getEmployeds() {
      getEmployed()
        .then(({ data }) => {
          this.employeds = data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getEmployeds();
  },
};
</script>