<template>
  <el-dialog
    width="40%"
    :visible.sync="modalOpen"
    :before-close="handleClose"
    :append-to-body="true"
    :destroy-on-close="true"
  >
    <span slot="title"
      ><i class="mdi mdi-cash-refund"></i> Comprobantes de Egreso</span
    >
    <el-table
      :data="tableData"
      v-loading="loadingTable"
      height="400"
      style="width: 100%"
    >
      <el-table-column label="Consecutivo" prop="consecutivo">
      </el-table-column>
      <el-table-column label="Fecha" prop="fecha"> </el-table-column>
      <el-table-column label="Subtotal" prop="subtotal"> </el-table-column>
      <el-table-column label="Descuento" prop="discount1"> </el-table-column>
      <el-table-column align="right">
        <template slot-scope="scope">
          <el-popconfirm
            confirm-button-text="Si"
            cancel-button-text="No, Cancelar"
            icon="el-icon-info"
            icon-color="red"
            title="Deseas eliminar este egreso?"
            @confirm="handleDelete(scope.$index, scope.row)"
          >
            <el-button
              slot="reference"
              size="mini"
              type="danger"
              >Eliminar</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div
      v-if="tableData.length > 0"
      class="pagination-box"
      style="text-align: end; padding-top: 10px"
    >
      <el-pagination
        layout="total, prev, pager, next"
        :page-size="pageSize"
        :total="total_rows"
        @current-change="handleCurrentPage"
      ></el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">Cancel</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getExpenses, deleteExpenses } from "@/api/document";
export default {
  name: "ListExpenses",
  props: ["modalOpen"],
  watch: {
    modalOpen: function (val) {
      if (val) {
        this.getExpensesData();
      }
    },
  },
  data() {
    return {
      loadingTable: false,
      type_id: 6, // comprobantes de egreso
      tableData: [],
      //
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
    };
  },
  methods: {
    getExpensesData() {
      this.loadingTable = true;
      getExpenses(this.pageSize, this.currentPage, this.type_id)
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loadingTable = false;
        })
        .catch(function (error) {
          this.loadingTable = false;
          console.log(error);
        });
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getExpensesData();
    },
    handleDelete(index, row) {
        console.log('eliminando');
      this.loadingTable = true;
      deleteExpenses(row.id)
        .then(({ data }) => {
          if (data.code === 200) {
            this.getExpensesData();
            this.loadingTable = false;
          } else {
            this.$message.error("Error: " + data.error);
          }
        })
        .catch(function (error) {
          this.loadingTable = false;
          console.log("Err: ", error);
        });
    },
    closeModal() {
      this.$emit("close");
    },
    handleClose(done) {
      this.$emit("close");
    },
  },
};
</script>