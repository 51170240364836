import request from '@/utils/request'

export function getPay(id) {
  return request({
    url: 'provider/getPay/' + id,
    method: 'get'
  });
}

export function getDocumentById(id) {
  return request({
    url: 'document/getDocumentById/' + id,
    method: 'get'
  });
}

export function saveDocument(data) {
  return request({
    url: 'document/store',
    method: 'post',
    data
  });
}

export function getDocumentPay(payment_id, consecutive) {
  return request({
    url: 'document/getDocumentPay/' + payment_id + '/' + consecutive,
    method: 'get'
  });
}

export function searchProduct(data) {
  return request({
    url: 'document/searchProduct',
    method: 'post',
    data
  });
}
export function searchCupon(number) {
  return request({
    url: 'document/searchCupon/' + number,
    method: 'get',
  });
}

export function getDocumentsToday(page_size, currentPage, consecutive, date, client, branch_id) {
  return request({
    url: 'document/getDocumentsToday/'  + page_size + '/' + branch_id + '/' + consecutive +
    '/' + date + '/' + client + '?page=' + currentPage,
    method: 'get',
  });
}

export function getDocumentForPay(payment_id, consecutive) {
  return request({
    url: 'document/getDocumentForPay/' + payment_id + '/' + consecutive,
    method: 'get',
  });
}
