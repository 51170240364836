import Vue from 'vue'
import Router from 'vue-router'

import {
  getUser,
  getBaseCash,
  getDataPos
} from '@/utils/auth'

//apps
import Pos from '../views/apps/pos/Pos.vue'
import BaseCash from '../views/apps/pos/BaseCash.vue'
import Refund from '../views/apps/refund/Form.vue'
import DailyReport from '../views/apps/dailyReport/Index.vue'
import Product from '../views/apps/product/Index.vue'
import Category from '../views/apps/product/Category.vue'
import Amortization from "../views/apps/amortization/Index.vue";

import Expenses from '../views/apps/Expenses/Index.vue'

//pages
import autentications from './modules/autentications'
import inventory from './modules/inventory'
import reposrts from './modules/reposrts'
import administration from './modules/administration'

//errors
import errors from './modules/errors'

import layouts from '../layout'
import store from '../store/'

Vue.use(Router)


const router = new Router({
  mode: 'history',
  base: '/app',
  routes: [{
      path: '/pos',
      alias: '/pos',
      name: 'POS',
      component: Pos,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['POS'],
        roles: ['admin', 'cashier', 'management'],
        icon: 'cart-outline'
      }
    },
    {
      path: '/refund',
      alias: '/refund',
      name: 'Devoluciones',
      component: Refund,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: false,
        tags: ['Devoluciones'],
        roles: ['admin', 'management'],
        icon: 'cart-arrow-up'
      }
    },
    {
      path: '/expenses',
      alias: '/expenses',
      name: 'Egresos',
      component: Expenses,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Egresos'],
        roles: ['admin', 'management'],
        icon: 'cash-refund'
      }
    },
    {
      path: '/amortization',
      name: 'Amortizaciones',
      component: Amortization,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Amortizaciones','Pago de creditos'],
        roles: ['admin',"cashier",'management'],
        icon: 'cash-multiple'
      }
    },
    {
      path: '/products',
      alias: '/products',
      name: 'Productos',
      component: Product,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: false,
        tags: ['Informe Diario'],
        roles: ['admin'],
        icon: 'shopping'
      }
    },
    {
      path: '/category',
      alias: '/category',
      name: 'Categorias',
      component: Category,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Producto', 'Categoria'],
        roles: ['admin'],
        icon: 'tag-multiple'
      }
    },
    // {
    //   path: '/daily_report',
    //   alias: '/daily_report',
    //   name: 'Informe Diario',
    //   component: DailyReport,
    //   meta: {
    //     auth: true,
    //     nav: true,
    //     layout: layouts.navLeft,
    //     searchable: false,
    //     tags: ['Informe Diario'],
    //     roles: ['admin', 'cashier'],
    //     icon: 'chart-bar'
    //   }
    // },
    inventory,
    reposrts,
    administration,
    {
      path: '/base_cash',
      alias: '/base_cash',
      name: 'Base',
      component: BaseCash,
      meta: {
        auth: true,
        nav: false,
        layout: layouts.contenOnly,
        searchable: false,
        tags: ['BaseCash'],
        roles: ['admin', 'cashier', 'management'],
        icon: 'cash-register'
      }
    },
    {
      path: '/logout',
      beforeEnter(to, from, next) {
        auth.logout()
        next({
          path: '/login'
        })
      }
    },
    autentications,
    errors,
  ]
})


const l = {
  contenOnly() {
    store.commit('setLayout', layouts.contenOnly)
  },
  navLeft() {
    store.commit('setLayout', layouts.navLeft)
  },
  navRight() {
    store.commit('setLayout', layouts.navRight)
  },
  navTop() {
    store.commit('setLayout', layouts.navTop)
  },
  navBottom() {
    store.commit('setLayout', layouts.navBottom)
  },
  set(layout) {
    store.commit('setLayout', layout)
  }
}

//insert here login logic
const auth = {
  loggedIn() {
    return store.getters.isLogged
  },
  logout() {
    store.commit('setLogout')
  },
  user() {
    return getUser()
  },
  baseCash() {
    return getBaseCash()
  },
  dataPos() {
    return getDataPos()
  }
}

router.beforeEach((to, from, next) => {
  let authrequired = false
  if (to && to.meta && to.meta.auth)
    authrequired = true
  if (authrequired) {
    if (auth.loggedIn()) {
      if (to.name === 'login') {
        window.location.href = '/app'
        return false
      } else {
        if (!to.meta.roles) {
          next()
          return false
        }
        // console.log('user: ',auth.user());
        // SALTAR VALIDACION DE BASE DE CAJA
        let baseCashEnter = true;
        for (var i = 0; i < auth.user().roles.length; i++) {
          if (auth.user().roles[i].guard_name === 'employed') {
            baseCashEnter = false
          }
        }
        if (!auth.baseCash() && to.name !== 'Base' && baseCashEnter) {
          next({
            name: 'Base'
          })
          return false
        }

        let enter = false
        for (var i = 0; i < auth.user().roles.length; i++) {
          if (to.meta.roles.includes(auth.user().roles[i].guard_name)) {
            enter = true
          }
        }
        if (!enter)
          next({
            name: 'Unauthorized'
          })
        next()
      }
    } else {
      if (to.name !== 'login') {
        window.location.href = '/app/login'
        return false
      }
      next()
    }
  } else {
    if (auth.loggedIn() && to.name === 'login') {
      for (var i = 0; i < auth.user().roles.length; i++) {
        if (auth.user().roles[i].redirect_to) {
          window.location.href = '/app' + auth.user().roles[i].redirect_to;
        } else {
          window.location.href = "/app/404";
        }
      }
      return false
    } else {
      next()
    }
  }

  if (to && to.meta && to.meta.layout) {
    l.set(to.meta.layout)
  }
})

router.afterEach((to, from) => {
  setTimeout(() => {
    store.commit('setSplashScreen', false)
  }, 500)
})

export default router
