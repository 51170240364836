<template>
  <div>
    <el-table
      :data="tableData"
      v-loading="loadingTable"
      height="400"
      style="width: 100%"
    >
      <div slot="empty">
        <el-empty description="No hay datos"></el-empty>
      </div>
      <el-table-column prop="product.codigo_interno" label="Código" width="200">
        <template slot-scope="scope">
          <div>
            <i class="mdi mdi-barcode"></i>
            {{ (scope.row.product.codigo_interno !== '') ? scope.row.product.codigo_interno : '' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="product.descripcion" label="Producto">
        <template slot-scope="scope">
          <div>
            <i class="mdi mdi-cart-outline"></i>
            {{ scope.row.product.descripcion }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="product.category.descripcion" label="Categoria">
        <template slot-scope="scope">
          <div>
            <i class="el-icon-collection-tag"></i>
            {{ scope.row.product.category.descripcion }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="product.unit_measurement.name" label="Unidad de medida">
        <template slot-scope="scope">
          <div>
            <i class="el-icon-collection-tag"></i>
            {{ scope.row.product.unit_measurement.name }} ({{ scope.row.product.unit_measurement.abbreviation }})
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="cant_final" label="Cantidad" width="150">
        <template slot-scope="scope">
          <div>
            {{ scope.row.cant_final }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="employed.complete_name" label="Empleado"></el-table-column>
    </el-table>
    <div
      v-if="tableData.length > 0"
      class="pagination-box animated fadeInRight"
      style="text-align: end; padding-top: 10px"
    >
      <el-pagination
        layout="total, prev, pager, next"
        :page-size="pageSize"
        :total="total_rows"
        @current-change="handleCurrentPage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { getDailyInventoryEmployed } from "@/api/document";
export default {
  name: "ListInventory",
  props: ["get", "form"],
  watch: {
    get: function (val) {
      if (val) {
        console.log("form:", this.form);
        this.getAll();
      }
    },
  },
  data() {
    return {
      loadingTable: false,
      tableData: [],
      //
      pageSize: 50,
      currentPage: 1,
      total_rows: 0,
    };
  },
  methods: {
    getAll() {
      this.loadingTable = true;
      var employed_id =
        this.form.employed_id != "" ? this.form.employed_id : "null";
      var dates =
        this.form.dates != "" ? this.form.dates : "null";
        getDailyInventoryEmployed(this.pageSize, this.currentPage, employed_id, dates)
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loadingTable = false;
          this.$emit("complete", true);
          this.$emit("table-data", this.tableData);
        })
        .catch(function (error) {
          console.log(error);
          this.$emit("complete", true);
        });
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getAll();
    },
  },
  created() {
    // this.getAll();
  },
};
</script>

<style lang="scss" scoped>
.btn-input {
  padding: 3px;
}
</style>