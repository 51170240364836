import request from '@/utils/request'

export function store(data) {
  return request({
    url: 'employed/store',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: 'employed/update',
    method: 'put',
    data
  })
}

export function deleteData(id) {
  return request({
    url: 'employed/destroy/' + id,
    method: 'delete'
  });
}

export function getAll() {
  return request({
    url: 'employed/getAll',
    method: 'get'
  });

}

export function getById(id) {
  return request({
    url: 'employed/getById/' + id,
    method: 'get'
  });
}
