<template>
  <div class="notification-box">
    <div
      class="notify"
      v-for="n in notifications"
      :key="n.id"
      @click="handleClick(n)"
    >
      <div class="flex">
        <div class="n-title box grow">
          <strong> {{ n.type.descripcion }}</strong>
        </div>
        <div class="n-date">
          {{ n.fecha }} <i class="mdi mdi-calendar-month-outline"></i>
        </div>
      </div>
      <div class="n-desc">
        <i class="mdi mdi-file-check-outline"></i> {{ n.consecutivo }}
      </div>
      <div class="n-desc">
        <i class="mdi mdi-store"></i> {{ n.branch.razon_social }}
      </div>
    </div>
    <!-- <div class="see-all">See all notifications</div> -->
  </div>
</template>

<script>
import { acceptTransfer, printTransfer } from "@/api/document";
import { getDocument } from "@/utils/print/transfer";
export default {
  name: "NotificationBox",
  props: ["notifications", "user"],
  data() {
    return {
      //
    };
  },
  methods: {
    handleClick(data) {
      var index = null;
      this.notifications.find(function (el, indx) {
        if (el.id === data.id) {
          index = indx;
        }
      });
      this.notificationClick(index, data);
    },
    notificationClick(idx, row) {
      var dataTransfer = {
        documento_id: row.id,
        tipo_id: row.tipo_id,
        bodega_id: this.user.branch.id,
      };
      var me = this;
      this.$swal
        .fire({
          title:
            "<strong>Acepto a conformidad " +
            row.type.descripcion +
            " '" +
            row.consecutivo +
            "'</strong>",
          icon: "warning",
          html:
            "Confirmo que he verificado los productos y cantidades recibidas.",
          showCloseButton: true,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonColor: "#13ce66",
          denyButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          focusConfirm: false,
          showLoaderOnConfirm: true,
          confirmButtonText:
            '<i class="mdi mdi-clipboard-check-outline"></i> Aceptar ' +
            row.type.descripcion +
            "!",
          denyButtonText: `<i class="mdi mdi-printer-settings"></i> Solo Imprimir`,
          cancelButtonText:
            '<i class="mdi mdi-close-circle-outline"></i> Cerrar',
          preConfirm: () => {
            return acceptTransfer(dataTransfer)
              .then(({ data }) => {
                return data;
              })
              .catch(function (error) {
                me.$swal.showValidationMessage(
                  `Error: ${error.response.data.message}`
                );
              });
          },
          allowOutsideClick: () => !me.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: "success",
              title: "Perfecto!",
              text: "Documento generado y aceptado con exito!",
            });
            // this.print(idx, row);
            me.notifications.splice(idx, 1);
            me.$emit("quantity", me.notifications.length);
          } else if (result.isDenied) {
            this.print(idx, row, true);
            me.$message({
							dangerouslyUseHTMLString: true,
							iconClass: "el-icon-printer",
              message: "&nbsp; Imprimiendo documento...",
              type: "success",
            });
          }
        })
        .catch(function (error) {
          me.$swal.showValidationMessage(
            `Error: ${error.response.data.message}`
          );
        });
    },
    print(idx, row, onlyPrint) {
      let me = this;
      getDocument({id:row.id, branch_id:this.user.sucursal_id})
        .then((data) => {
          this.printTransferDocument(row);
          if (!onlyPrint) {
            me.notifications.splice(idx, 1);
          }
          me.$emit("quantity", me.notifications.length);
        })
        .catch(function (error) {
          console.log(error);
          me.$swal.fire({
            icon: "warning",
            title: "Atención!",
            text: `Error:  ${error}`,
          });
        });
    },
    printTransferDocument(row) {
      let me = this;
      printTransfer({document_id : row.id, branch_id:this.user.sucursal_id})
        .then((data) => {
          console.log(data);
        })
        .catch(function (error) {
          console.log(error);
          me.$swal.fire({
            icon: "warning",
            title: "Atención!",
            text: `Error:  ${error}`,
          });
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/_variables";

.notification-box {
  color: $text-color;

  .notify {
    margin-bottom: 20px;
    max-height: 70px;
    overflow: hidden;
    cursor: pointer;
    // position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 20px;
      box-shadow: 0px -20px 20px -10px $background-color inset;
    }

    .n-title {
      text-align: left;
      padding-right: 10px;
    }
    .n-desc {
      color: transparentize($text-color, 0.5);
      // margin-left: 20px;
    }

    &:hover {
      color: $text-color-accent;

      .n-desc {
        color: transparentize($text-color-accent, 0.5);
      }
    }
  }

  .see-all {
    &:hover {
      color: $text-color-accent;
    }
  }
}
</style>

