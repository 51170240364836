<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="50">
        <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="9" :xl="9">
          <h2>Datos de registro</h2>
          <el-form :model="form" ref="form" status-icon label-width="150px" size="medium" class="form">
            <el-form-item label="Descripción" prop="name"
              :rules="[{ required: true, message: 'Campo Obligatorio' }]">
              <el-input type="text" v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Abreviatura" prop="abbreviation"
              :rules="[{ required: true, message: 'Campo Obligatorio' }]">
              <el-input type="text" v-model="form.abbreviation" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="success" @click="submitForm('form')" :loading="loading" icon="el-icon-check"
                v-if="!edit">Guardar</el-button>
              <el-button size="small" type="warning" @click="submitForm('form')" :loading="loading" icon="el-icon-edit" v-else>
                Actualizar</el-button>
              <el-button size="small" @click="resetForm('form')" icon="el-icon-close">Cancelar</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <!-- grid -->
        <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="15" :xl="15">
          <el-table :data="
            tableData.filter(
              (data) =>
                !search ||
                data.name.toLowerCase().includes(search.toLowerCase())
            )
          " :default-sort="{ prop: 'name', order: 'descending' }" v-loading="loadingTable"
            style="width: 100%; margin-top: 20px" height="400">
            <div slot="empty">
              <el-empty description="No hay datos"></el-empty>
            </div>
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column label="Descripción" prop="name" sortable></el-table-column>
            <el-table-column label="Abreviatura" prop="abbreviation" sortable></el-table-column>
            <el-table-column align="right" width="200">
              <template slot="header" slot-scope="scope">
                <el-input v-model="search" size="mini" placeholder="Diginte para buscar..." />
              </template>
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="Editar" placement="top">
                  <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit"
                    circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
                  <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)"
                    icon="el-icon-delete" circle></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getAll, store, update, deleteData } from "@/api/administration/unitMeasurement.js";
export default {
  name: "UnitMeasurement",
  data() {
    return {
      loading: false,
      loadingTable: false,
      edit: false,
      search: "",
      tableData: [],
      form: {
        name: "",
        abbreviation: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Ingresa una descripción",
            trigger: "blur",
          },
        ],
        abbreviation: [
          {
            required: true,
            message: "Ingresa una abreviatura",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    getAll() {
      this.loadingTable = true;
      getAll()
        .then(({ data }) => {
          this.tableData = data;
          this.loadingTable = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    submitForm(formName) {
      this.loading = true;
      this.loadingTable = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.edit) {
            this.updateForm();
          } else {
            this.saveForm();
          }
        } else {
          this.loading = false;
          this.loadingTable = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveForm() {
      store(this.form)
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 200) {
            this.getAll();
            this.resetForm("form");
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function (error) {
          this.loading = false;
          this.loadingTable = false;
          console.log("Err: ", error);
        });
    },
    updateForm() {
      update(this.form)
        .then(({ data }) => {
          this.loading = false;
          if (data.code === 200) {
            this.getAll();
            this.resetForm("form");
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function (error) {
          this.loading = false;
          this.loadingTable = false;
          console.log("Err: ", error);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.edit = false;
      this.loading = false;
      this.loadingTable = false;
    },
    handleEdit(index, row) {
      this.edit = true;
      this.form.id = row.id;
      this.form.name = row.name;
      this.form.abbreviation = row.abbreviation;
    },
    handleDelete(index, row) {
      let me = this;
      this.loadingTable = true;
      this.$swal
        .fire({
          title: "<strong>Atención!</strong>",
          icon: "warning",
          html: "Deseas borrar esta unidad de medida?",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          focusConfirm: false,
          confirmButtonText:
            '<i class="mdi mdi-clipboard-check-outline"></i> Si',
          cancelButtonText:
            '<i class="mdi mdi-close-circle-outline"></i> Cancelar',
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteData(row.id)
              .then(({ data }) => {
                if (data.code === 200) {
                  this.getAll();
                  this.resetForm("form");
                  this.loadingTable = false;
                } else {
                  this.$message.error("Error! " + data.error.errorInfo[2]);
                }
              })
              .catch(function (error) {
                this.loadingTable = false;
                console.log("Err: ", error);
              });
          } else {
            me.loadingTable = false;
          }
        });
    },
  },
  created() {
    this.getAll();
  },
};
</script>