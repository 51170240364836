
const drawer = {
  state:{
    open_drawer:false,
    action_drawer:'',
    data: {
      show_btn: false,
      edit: false,
      title: '',
      icon: 'el-icon-info',
      component_active: '',
    },
  },
  mutations:{
    SET_OPEN_DRAWER: (state, data) => {
      state.open_drawer = data
    },
    SET_ACTION_DRAWER: (state, data) => {
      state.action_drawer = data
    },
    SET_DATA: (state, data) => {
      state.data = data
    },
  },
  actions:{
    
  }
}

export default drawer