import request from '@/utils/request'

export function login(username, password, remember) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: {
      email: username,
      password,
      remember_me: remember
    }
  })
}

export function findBaseCash(data) {
  return request({
    url: '/user/findBaseCash',
    method: 'post',
    data
  })
}

export function user() {
  return request({
    url: '/user',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: 'api/auth/logout',
    method: 'get'
  })
}

export function getConfig() {
  return request({
    url: '/auth/config',
    method: 'get'
  })
}

export function configBySlug(name) {
  return request({
    url: '/auth/configBySlug/' + name,
    method: 'get'
  })
}

export function findPrincipalBranch() {
  return request({
    url: '/user/findPrincipalBranch',
    method: 'post'
  })
}
