
const document = {
  state:{
    typeDocument:{},
    data:null,
    returnMoney:false,
  },
  mutations:{
    SET_REFUND_TYPE: (state, type) => {
      state.typeDocument = type
    },
    SET_REFUND_DATA: (state, data) => {
      state.data = data
    },
    SET_RETURN_MONEY: (state, return_money) => {
      state.returnMoney = return_money
    },
  },
  actions:{
    
  }
}

export default document