<template>
  <el-dialog title="Listado de abonos" width="50%" :visible.sync="openModalLi" :close-on-click-modal="false"
    :close-on-press-escape="false" :before-close="closeModalList" :destroy-on-close="true" top="5vh">

    <!-- <el-row :gutter="12">
      <el-col> -->
        <el-table :data="tableData" show-summary :summary-method="getSummaries" v-loading="loading"
          style="width: 100%; margin-top: 20px" size="samll">
          <div slot="empty">
            <el-empty description="No hay datos"></el-empty>
          </div>
          <el-table-column label="Fecha" prop="credit_date" sortable>
            <template slot-scope="scope">
              <div><strong><i class="el-icon-date"></i> {{ scope.row.credit_date }}</strong></div>
            </template>
          </el-table-column>
          <el-table-column label="Factura" prop="invoice" sortable>
            <template slot-scope="scope">
              <div><strong>{{ scope.row.invoice }}</strong></div>
            </template>
          </el-table-column>
          <el-table-column label="Abono" prop="credit_value" sortable>
            <template slot-scope="scope">
              <div class="total_pay">$ {{ formatNumber(scope.row.credit_value) }}</div>
            </template>
          </el-table-column>

          <!-- <el-table-column label="Acciones" width="90">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="Crear abono" placement="top">
                <el-button size="mini" type="success" @click="handleCreate(scope.$index, scope.row)"
                  icon="el-icon-document-add" circle></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="Listar abonos" placement="top">
                <el-button size="mini" type="primary" @click="handleList(scope.$index, scope.row)" icon="el-icon-files"
                  circle></el-button>
              </el-tooltip>
            </template>
          </el-table-column> -->
        </el-table>
        <!-- <div v-if="tableData.length > 0" class="pagination-box animated fadeInRight"
          style="text-align: end; padding-top: 10px">
          <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
            @current-change="handleCurrentPage"></el-pagination>
        </div> -->
      <!-- </el-col>
    </el-row> -->
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="openModalForm" icon="el-icon-plus" type="success">Registrar abono</el-button>
      <el-button size="small" @click="closeModalList" icon="el-icon-close">Cerrar</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formatInt, formatNumber } from "@/utils/number";
import { actualDate } from "@/utils/date";
import {
  getAll
} from "@/api/amortization";
export default {
  name: 'ModalListAmortization',
  props: ['openModalLi', 'dataFormList'],
  watch: {
    dataFormList: {
      deep: true,
      handler(val, oldVal) {
        if (Object.keys(val).length !== 0) {
          this.getAll(val);
        }
      },
    },
  },
  data() {
    return {
      tableData: [],
      loading: false,
      // Pagination
      pageSize: 100,
      currentPage: 1,
      total_rows: 0,
    }
  },
  methods: {
    openModalForm(){
      this.$emit('closeModalList', {'open':true,'data':this.dataFormList});
    },
    closeModalList(refreshTable) {
      this.loading = false;
      let dataEmit = null
      if (refreshTable === true) {
        dataEmit = { refreshTable: true };
      }
      this.$emit('closeModalList', dataEmit);
    },
    getAll(datas) {
      this.loading = true;
      getAll(this.pageSize, this.currentPage, datas.document.consecutivo)
        .then(({ data }) => {
          let datas = data
          this.loading = false;
          this.total_rows = datas.total;
          this.tableData = datas.data;
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
          this.$message.error("Oops", "Error.");
        });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Abono total';
          return;
        }
        if (index === 1) {
          sums[index] = '';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = '$ ' + values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getAll(this.dataFormList);
    },
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(formatInt(number));
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
  },
  created() {
    //
  },
}
</script>

<style lang="scss" scope>
.content-box {
  display: grid;
  place-items: center;
}

.icon-box {
  font-size: 50px;
}

.el-autocomplete {
  width: 100%;
}
</style>