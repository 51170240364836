<template>
  <el-dialog
    title="Cargar Archivo de Inventario"
    width="30%"
    :visible.sync="modalOpen"
    :before-close="handleClose"
    :append-to-body="true"
    :destroy-on-close="true"
  >
    <el-upload
      class="upload-file"
      drag
      ref="upload"
      :limit="1"
      :action="baseURL"
      :auto-upload="false"
      :on-success="submitComplete"
      :on-error="submitError"
      accept=".xlsx"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        Suelta tu archivo aquí o <em>haz clic para cargar</em>
      </div>
      <div slot="tip" class="el-upload__tip">Solo archivos xlsx</div>
      <div slot="tip" class="el-upload__tip">
        Descarga plantilla del archivo
        <el-link type="primary" :href="baseURLfile"
          >aquí <i class="el-icon-download"></i
        ></el-link>
      </div>
    </el-upload>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal()" icon="el-icon-close" size="small"
        >Cancelar</el-button
      >
      <el-button
        style="margin-left: 10px"
        size="small"
        type="success"
        icon="el-icon-upload"
        @click="submitUpload"
        :loading="loading"
        >Cargar inventario</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: ["modalOpen"],
  data() {
    return {
      baseURL: "",
      loading: false,
      baseURLfile:
        process.env.VUE_APP_ROOT + "assets/app/download/CARGUE.xlsx",
    };
  },
  methods: {
    submitUpload() {
      let me = this;
      this.loading = true;
      this.baseURL =
        process.env.VUE_APP_ROOT_API + "/document/uploadLoaddocument";
      setTimeout(() => {
        me.$refs.upload.submit();
      }, 1000);
    },
    submitComplete(response, file, fileList) {
      this.loading = false;
      if (response.code === 200) {
        this.$message({
          type: "success",
          message: "Carga Exitosa",
        });
        this.$emit("refresh");
        this.closeModal();
      } else {
        this.$message({
          type: "warning",
          message: "Error: " + response.error.errorInfo[2],
        });
      }
    },
    submitError(err, file, fileList) {
      this.loading = true;
      console.log(err);
    },
    closeModal() {
      this.$emit("close");
    },
    handleClose(done) {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.upload-file {
  text-align: center;
}
</style>