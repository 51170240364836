//pages
import Index from '@/views/pages/authentication/Index.vue'
import Login from '@/views/pages/authentication/Login.vue'
import Register from '@/views/pages/authentication/Register.vue'
import ForgotPassword from '@/views/pages/authentication/ForgotPassword.vue'
import layouts from '@/layout'

export default {
	path: 'authentication',
	name: 'Authenticación',
	component: Index,
	redirect: { path: '/register', name: 'register' },
	icon: 'lock',
	auth: true,
	meta: {
		nav: true,
		roles: ['admin']
	},
	children: [
		{
			path: '/login',
			name: 'login',
			component: Login,
			meta: {
				nav: false,
				layout: layouts.contenOnly
			}
		},
		{
			path: '/register',
			name: 'Registrar Usuario',
			component: Register,
			meta: {
				nav: true,
				layout: layouts.navLeft,
				roles: ['admin'],
				icon: 'account-plus-outline',
			}
		},
		{
			path: '/forgot-password',
			name: 'forgot-password',
			component: ForgotPassword,
			meta: {
				nav: false,
				layout: layouts.contenOnly
			}
		}
	]
}
