<template>
  <div>
    <el-table :data="tableData" v-loading="loadingTable" height="400" style="width: 100%">
      <div slot="empty">
        <el-empty description="No hay datos"></el-empty>
      </div>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="product.code" label="Código">
        <template slot-scope="scope">
          <div>
            <el-tooltip class="item" effect="dark" content="Entra" placement="left">
              <el-button type="success" icon="el-icon-download" size="mini" class="btn-input" circle></el-button>
            </el-tooltip>
            {{ scope.row.product.code }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="product.description" label="Producto"></el-table-column>
      <el-table-column prop="product.category.descripcion" label="Categoria">
        <template slot-scope="scope">
          <div>
            <i class="el-icon-collection-tag"></i>
            {{ scope.row.product.category.descripcion }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="quantity" label="Cantidad a cargar">
      </el-table-column>
      <el-table-column prop="employed.complete_name" label="Empleado">
      </el-table-column>
      <el-table-column label="Acciones" width="110" class="align-vertical-middle">
        <template slot-scope="scope">
          <el-popconfirm confirmButtonText="Si" cancelButtonText="No, Cancelar" icon="el-icon-info" iconColor="red"
            title="Deseas eliminar este registro?" @confirm="handleDelete(scope.row)">
            <el-button slot="reference" size="mini" type="danger" circle>
              <i class="el-icon-delete"></i>
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="tableData.length > 0" class="pagination-box animated fadeInRight"
      style="text-align: end; padding-top: 10px">
      <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
        @current-change="handleCurrentPage"></el-pagination>
    </div>
  </div>
</template>
<script>
import { getLoadInventoryAll, loadInventoryDelete } from "@/api/document";
export default {
  name: "ListInputSetting",
  props: ["get"],
  watch: {
    get: function (val) {
      if (val) {
        this.getAll();
      }
    },
    tableData: function (val) {
      if (val) {
        if (val.length > 0) {
          this.$emit('quantity_detail', false);
        } else {
          this.$emit('quantity_detail', true);
        }
      }
    }
  },
  data() {
    return {
      loadingTable: false,
      tableData: [],
      type_id: 7, // ajuste de entrada
      //
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
      simpleProductInventory: [],
    };
  },
  methods: {
    getAll2() {
      this.loadingTable = true;
      getLoadInventoryAll(this.pageSize, this.currentPage, this.type_id)
        .then(async ({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loadingTable = false;
          data.data.forEach(element => {
            this.getProductSimple(element)
          });
          await new Promise((resolve) => setTimeout(resolve, 1000));
          this.setSimpleProductsInventory(sp, false);
          this.$emit("complete", true);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async getAll() {
      try {
        this.loadingTable = true;
        const { data } = await getLoadInventoryAll(this.pageSize, this.currentPage, this.type_id);
        this.tableData = data.data;
        this.total_rows = data.total;
        this.loadingTable = false;

        if(this.simpleProductInventory.length === 0){
          await Promise.all(data.data.map(element => this.getProductSimple(element)));
        }
        await this.$emit("complete", this.simpleProductInventory);
      } catch (error) {
        console.log(error);
      }
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getAll();
    },
    handleDelete(row) {
      // this.loadingTable = true;
      // return;
      loadInventoryDelete(row.id)
      .then(({ data }) => {
        if (data.code === 200) {
          this.getAll();
          this.loadingTable = false;
          this.$emit("delete", row);
          } else {
            this.$message.error("Error: " + data.error);
          }
        })
        .catch(function (error) {
          this.loadingTable = false;
          console.log("Err: ", error);
        });
    },
    async getProductSimple(data) {
      const sp = data.product.product_pivot.map((el) => {
        const id = parseInt(el.product_simple_id);
        const quantity = parseFloat(data.quantity) * parseFloat(el.quantity);
        return { id, quantity };
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.setSimpleProductsInventory(sp, false);
    },
    async setSimpleProductsInventory(sp) {
      const spf = await this.simpleProductInventory;
      sp.forEach((el) => {
        const found = spf.findIndex((element) => element.id === el.id);
        if (found !== -1) {
          this.simpleProductInventory[found].quantity += el.quantity;
        } else {
          this.simpleProductInventory.push(el);
        }
      });
    },
  },
  created() {
    this.getAll();
  },
};
</script>

<style lang="scss" scoped>
.btn-input {
  padding: 3px;
}
</style>