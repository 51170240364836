<template>
  <div>
    <el-row :gutter="20" style="margin-bottom: 20px">
      <el-col :span="16">
        <el-date-picker
          class="date-range"
          v-model="dateRange"
          size="small"
          type="daterange"
          range-separator="a"
          start-placeholder="Fecha inicial"
          end-placeholder="Fecha final"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="8">
        <el-button
          type="primary"
          size="mini"
          icon="el-icon-search"
          :loading="loadingBtn"
          @click="searchUpfByBranch"
          round
          >Buscar</el-button
        >
      </el-col>
    </el-row>

    <el-table
      v-loading="loading"
      :data="tableData"
      style="width: 100%"
      size="mini"
      :summary-method="getSummaries"
      border
      show-summary
    >
      <el-table-column prop="name" label="Vendedor"> </el-table-column>
      <el-table-column
        prop="invoices"
        label="Facturas"
        width="95"
        align="center"
        sortable
      >
      </el-table-column>
      <el-table-column
        prop="products"
        label="Und. Vendidas"
        width="130"
        align="center"
        sortable
      >
      </el-table-column>
      <el-table-column
        prop="upf"
        label="UPF"
        width="70"
        align="center"
        sortable
      >
        <template slot-scope="props">
          <div>{{ props.row.upf.toFixed(2) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="vpf" label="VPF" align="right" width="80" sortable>
        <template slot-scope="props">
          <div>$ {{ formatNumber(props.row.vpf) }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
} from "@/utils/number";
import { getUser } from "@/utils/auth";
import { actualDate } from "@/utils/date";
import { getUpfReport } from "@/api/branch";
export default {
  name: "Upf",
  data() {
    return {
      user: getUser(),
      date: actualDate(),
      loading: false,
      loadingBtn: false,
      totalDaysUpfStore: 0,
      totalUpfStore: 0,
      tableData: [],
      total: [],
      dateRange: [actualDate().substr(0, 10), actualDate().substr(0, 10)],
    };
  },
  mounted() {
    let me = this;
    setTimeout(() => {
      me.searchUpfByBranch();
    }, 500);
  },
  methods: {
    searchUpfByBranch() {
      this.loading = true;
      this.loadingBtn = true;
      var date = this.date.substr(0, 10);
      getUpfReport(null, this.user.branch.id, this.dateRange)
        .then(({ data }) => {
          this.loading = false;
          this.loadingBtn = false;
          this.getUpfStore(data.data);
          this.tableData = data.sellers;
          this.total = data.total;
        })
        .catch(function (error) {
          this.loading = false;
          this.loadingBtn = false;
          console.log(error);
        });
    },
    getUpfStore(data) {
      data.forEach((el) => {
        this.totalDaysUpfStore++;
        this.totalUpfStore += parseFloat(el.upf.toFixed(2));
      });
    },
    getSummaries(param) {
      let me = this;
      const { columns, data } = param;
      const sums = [];
      let invoices = 0;
      let products = 0;
      let upfRange = true;
      if (this.dateRange[0] === this.dateRange[1]) {
        upfRange = false;
      }
      // console.log(this.dateRange, upfRange);
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Total";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          if (index === 4) {
            let val = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = "$ " + formatNumber(roundToTheNearestAnything(val));
          } else {
            let val = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            if (index === 1) {
              invoices += val;
            }
            if (index === 2) {
              products += val;
            }
            if (index === 3) {
              if (upfRange) {
                // sacar promedio de upf de tienda por rango de fechas
                sums[index] = (me.totalUpfStore / me.totalDaysUpfStore).toFixed(
                  2
                );
              } else {
                // sacar promedio de upf de tienda con total productos y total invoices
                sums[index] = (products / invoices).toFixed(2);
              }
            } else {
              sums[index] = val;
            }
          }
        } else {
          sums[index] = "N/A";
        }
      });
      return sums;
    },
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(roundToTheNearestAnything(number));
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
  },
};
</script>

<style lang="scss" scope>
.el-range-editor--small.el-input__inner {
  width: 100% !important;
}
</style>