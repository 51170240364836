<template>
  <div>
    <el-table
      :data="expenses_detail"
      v-loading="loadingTable"
      style="width: 100%"
    >
      <div slot="empty">
        <el-empty description="No hay datos"></el-empty>
      </div>
      <el-table-column
        prop="description"
        label="Descripción / Concepto"
        min-width="300"
      >
        <template slot-scope="scope">
          <el-input
            placeholder="Descripción / Concepto"
            v-model="scope.row.description"
            clearable
          >
          </el-input>
        </template>
      </el-table-column>
      <el-table-column prop="quantity" label="Cantidad" min-width="130">
        <template slot-scope="scope">
          <el-input-number
            controls-position="right"
            placeholder="Cantidad"
            v-model="scope.row.quantity"
            :min="1"
          >
          </el-input-number>
        </template>
      </el-table-column>
      <el-table-column prop="cost" label="Valor" min-width="160">
        <template slot-scope="scope">
          <el-input placeholder="$ 0.00" v-model="scope.row.cost" clearable>
            <template slot="prepend">$</template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column prop="discount" label="Descuento" min-width="160">
        <template slot-scope="scope">
          <el-input placeholder="$ 0.00" v-model="scope.row.discount" clearable>
            <template slot="prepend">$</template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column
        label="Acciones"
        width="110"
        class="align-vertical-middle"
      >
        <template slot-scope="scope" v-if="expenses_detail.length > 1">
          <el-button
            slot="reference"
            size="mini"
            type="danger"
            circle
            @click="removeRowDetail(scope.$index, scope.row)"
          >
            <i class="el-icon-delete"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-link icon="el-icon-plus" type="primary" @click="addRow()"
        >Agregar otro registro</el-link
      >
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ListExpenses",
  computed: {
    ...mapGetters(["expenses_detail"]),
  },
  data() {
    return {
      loadingTable: false,
    };
  },
  methods: {
    ...mapActions('expenses',["addNewRowDetailAction", "removeRow"]),
    addRow() {
      this.addNewRowDetailAction({
        description: "",
        quantity: 1,
        cost: "",
        discount: "",
      });
    },
    removeRowDetail(index, data) {
      this.removeRow(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-input {
  padding: 3px;
}
.el-input-number {
  width: 100%;
}
</style>